import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext/AuthContext";
import { logoutUrl } from "../utils/constants";
import { XSquare } from "react-bootstrap-icons";
 
const useApis = (initialUrl, initialMethod = "GET") => {
  const [serverError, setServerError] = useState("");
  const [tokenError, setTokenError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resultGet, setResultGet] = useState(null);
  const [resultPost, setResultPost] = useState(null);
  const [url, setUrl] = useState(initialUrl);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState("");
  const [isOnline, setIsOnline] = useState(navigator.onLine); // Track connection status
 
  const refreshFailed = false;
  const isRefreshing = useRef(false); // Tracks if refresh is in progress
  const pendingRequests = useRef([]); // Tracks pending requests
 
  const navigate = useNavigate();
  const auth = useAuth();
  const [method, setMethod] = useState(initialMethod);
 
  const isTokenError = (errorMessage) => {
    return (
      errorMessage === "Token is missing" ||
      errorMessage === "Token has expired"
    );
  };
 
  const showTokenExpiredToaster = () => {
    setTokenError("Session expired. Please Login again!");
    auth?.showToast(
      "Error",
      "Session expired. Please Login again!",
      "../assets/mhc.png",
      <XSquare />
    );
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
      setServerError("");
    }, 3000);
    auth?.logout();
  };
 
  // Listen for online/offline events
  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);
 
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
 
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);
 
  const callApi = async (
    data = null,
    customUrl = null,
    customMethod = null,
    token,
    headers = token
      ? {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        "Content-Type": "application/json",
      }
      : {}
  ) => {
    if (!isOnline) {
      setServerError("No internet connection. Please check your connection.");
      setShowError(true);
      return;
    }
 
    setServerError("");
    setIsLoading(true);
 
    const requestUrl = customUrl || url;
    const requestMethod = customMethod || method;
 
    const filterUndefinedValues = (obj) => {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, value]) => value !== undefined)
      );
    };
 
    const sanitizedData = data ? filterUndefinedValues(data) : null;
 
    const config = {
      url: requestUrl,
      method: requestMethod,
      data: sanitizedData,
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
 
    try {
      const response = await axios(config);
      handleApiResponse(response, requestMethod);
      return response.data;
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };
 
  const handleApiResponse = (response, requestMethod) => {
    if (requestMethod !== "GET") {
      if (response.data) {
        setResultPost(response.data);
        setShowSuccess(true);
        setMessage({ postMessage: response.message });
        setModalShow(true);
      } else {
        setMessage("No data provided for the request.");
      }
    } else {
      setResultGet(response.data);
      setShowSuccess(true);
      if (response.data.message) {
        setMessage({ getMessage: response.message });
      }
    }
 
    if (response.status !== 200) {
      setShowError(true);
      setServerError(response.data);
    }
  };
 
  const handleApiError = (error) => {
    setShowError(true);
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.error ||
      error?.response?.data?.detail ||
      (error?.message === "Network Error"
        ? "No internet connection. Please check your connection."
        : error?.response?.data);
 
    if (isTokenError(errorMessage)) {
      showTokenExpiredToaster();
    } else {
      setServerError(errorMessage);
    }
 
    handleTokenExpiration(error);
  };
 
  const handleTokenExpiration = (error) => {
    if (
      error.response?.status === 401 &&
      error.response?.data?.token_expired
    ) {
      if (!refreshFailed) {
        pendingRequests.current.push(() => callApi());
        if (!isRefreshing.current) {
          isRefreshing.current = true; // Set to true while refreshing
          callApi(); // Retry the API call after refreshing the token
        }
      }
    } else if (error.response?.data?.unauthorized) {
      setTimeout(() => {
        auth.logout();
        callApi(null, logoutUrl, "POST");
        localStorage.clear()
        navigate("/login");
      }, 3000);
    } else if (error.response?.status === 401 && error.response?.data?.session_expired) {
      setServerError("Session has expired. Please log in again.");
      setTimeout(() => {
        auth.logout();
        callApi(null, logoutUrl, "POST");
        localStorage.clear()
        navigate("/login");
      }, 3000);
    }
  };
 
  return {
    callApi,
    setUrl,
    setMethod,
    serverError,
    tokenError,
    isLoading,
    resultGet,
    resultPost,
    showSuccess,
    showError,
    modalShow,
    message,
    setModalShow,
    setShowSuccess,
    setShowError,
    isOnline, // Expose online status
    toggleShowSuccess: () => setShowSuccess((prev) => !prev),
    toggleShowError: () => setShowError((prev) => !prev),
  };
};
 
export default useApis;