import React from 'react';
import { Button } from 'react-bootstrap';
import { BankHolidayHelper } from '../utils/utils';

// Endpoint for API, pulled from environment variables
const endpoint = process.env.REACT_APP_API_ENDPOINT;

const Banner = () => (
  <div className="bank-holiday-flag">
    <a
      className="flag-a"
      href="https://myhealthchecked.com/myhealthchecked-service-updates"
      target="_blank"
      rel="noopener noreferrer"
    >
      View our Christmas and New Year service hours
    </a>
    <Button
      variant="primary"
      className="read-more-btn"
      href="https://myhealthchecked.com/myhealthchecked-service-updates"
      target="_blank"
      rel="noopener noreferrer"
    >
      Read More
    </Button>
  </div>
);

export default () => {
  const isBankHoliday = BankHolidayHelper();

  if (process.env.NODE_ENV === 'development') {
    return (
      <div className="flag">
        dev mode, endpoint: <a href={endpoint}>{endpoint}</a>
      </div>
    );
  } else if ((process.env.REACT_APP_STAGING || isBankHoliday)) {
    return <Banner />;
  } else {
    return null;
  }
};
