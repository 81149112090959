import React from "react";
import { getScaleBar } from "../../stories/Accordion/Accordion";
import { Accordion, Card, Spinner } from "react-bootstrap";
import { image_headers } from "../../views/WellnessReports/ReportImages";
import { Link } from "react-router-dom";

const ReportHighlightSection = ({
  items,
  defaultActiveKey = ["0"],
  report_id,
  test_name,
  alwaysOpen = false
}) => {
  // Gets only the first 3 items
  const firstThreeItems = items?.slice(0, 3);

  const lastItem = firstThreeItems?.[firstThreeItems.length - 1];

  return (
    <>
      {firstThreeItems ? (
        <>
          <Card>
            <div className="border-bottom"></div>
            <Accordion
              defaultActiveKey={defaultActiveKey}
              alwaysOpen={alwaysOpen}
            >
              {firstThreeItems?.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header className="no-chevron-header">
                    <div className="d-flex align-items-center justify-content-center w-100">
                      <div className="circle-img">
                        <img
                          src={image_headers[item.tag]}
                          alt={item.header}
                          height={100}
                          width={100}
                          style={{ marginRight: "8px" }}
                        />
                      </div>
                      <div className="scale-bar">
                        <span
                          className="sm-heading"
                          style={{
                            fontSize: "1.25rem",
                            marginBottom: "0.5rem"
                          }}
                        >
                          {item.name}
                        </span>
                        {getScaleBar(item.value, item.effect)}
                      </div>
                    </div>
                  </Accordion.Header>
                </Accordion.Item>
              ))}
            </Accordion>
            <div className="border-bottom"></div>
            {lastItem && (
              <>
                <div className="link-date-footer">
                  <div className="row">
                    <div className="col-md-7">
                      <Link
                        to={`/wellness-report/${report_id}`}
                        className="float-left small-link"
                      >
                        View full {test_name} report
                      </Link>
                    </div>

                    <div className="col-md-5">
                      <ShortDate date={lastItem.date} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </Card>
        </>
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
    </>
  );
};

export default ReportHighlightSection;

const ShortDate = date => {
  const options = { month: "long", year: "numeric" };
  return (
    <div className="float-right short-timestamp">
      {new Intl.DateTimeFormat("en-GB", options).format(date.ts)}
    </div>
  );
};
