import React, { useEffect } from "react";
import useApis from "../../services/useAPI";
import { useAuth } from "../../components/AuthContext/AuthContext";
import DynamicForm from "../../stories/Forms/DynamicForm";
import { useNavigate } from "react-router-dom";
import { BoxArrowInRight, XSquare } from "react-bootstrap-icons";
let fields;
const VerifyOTP = () => {
  const { callApi, serverError, resultGet, resultPost } = useApis();
  const auth = useAuth();
  const navigate = useNavigate();
  const postUrl = `/api/dashboard/add-contact/`;
  const postUrl2 = `/api/dashboard/verify-otp`;

  const handleMFASubmit = async values => {
    const data = {
      otp: values.otp, // Use values.primary_contact_no
      user_id: localStorage.getItem("user_id")
    };

    const url = values.otp ? postUrl2 : postUrl;

    callApi(data, url, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json"
    });
  };

  fields = [
    {
      controlId: "2",
      label: "Verify OTP",
      type: "password",
      name: "otp",
      as: "",
      required: true,
      placeholder: "",
      size: "text",
      readOnly: false,
      disabled: false,
      aria_describedby: "val2",
      className: "form-group my-4"
    },

    {
      controlId: "3",
      label: "Verify OTP",
      type: "submit",
      size: "md",
      variant: "primary",
      className: "btn-login mt-4"
    }
  ];
  useEffect(() => {
    if (resultPost) {
      auth?.showToast(
        "Success",
        resultPost,
        "../assets/mhc.png",
        <BoxArrowInRight />
      );
      navigate("/");
    } else {
      serverError &&
        auth?.showToast("Error", serverError, "../assets/mhc.png", <XSquare />);
    }
  }, [resultGet, resultPost, serverError]);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="py-4">
            <h1>EnableMFA</h1>
            <div className="my-4">
              <p>
                To help keep your account safe, we’re now asking all customers
                to turn on Multi-Factor Authentication (MFA).
              </p>{" "}
              <p>
                {" "}
                By adding this extra security step, your account will be even
                more protected. Simply provide your phone number, and we’ll send
                you a quick verification code to complete the setup.
              </p>
            </div>
            <DynamicForm
              fields={fields}
              onSubmit={handleMFASubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
