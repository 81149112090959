import React from "react";
import { Container, Button } from "react-bootstrap"; // Import necessary Bootstrap components
import { Link } from "react-router-dom"; // Import Link for navigation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon for icons
import { faCog } from "@fortawesome/free-solid-svg-icons"; // Import specific icon (faCog)
import Notice from "./Notice";
import EmailLink from "./EmailLink";

/**
 * ErrorDisplay component
 *
 * This component displays error messages based on the provided 'error' and 'consent' props.
 * It handles different scenarios like showing a general error message, displaying a maintenance mode message,
 * or providing options to contact support.
 * @returns {JSX.Element} The rendered component displaying the appropriate error message and actions.
 */

export function ErrorDisplay({ error, consent, alreadyActivated }) {
  if (alreadyActivated) {
    return (
      <Container className="my-5">
        <h1>Error</h1>
        <p variant="danger">{error}</p>
        <p>Barcode is already activated, please check the status of you barcode and try again.</p>
        <Button
          as={Link}
          to="/tests/bloods/activate"
          variant="primary"
          className="mr-2"
        >
          Previous step
        </Button>
        <Button
          as="a"
          href="https://myhealthchecked.com/contact"
          target="_blank"
          rel="noopener noreferrer"
          variant="outline-primary"
        >
          Contact us
        </Button>
      </Container>
    );
  }
  if (consent) {
    return (
      <Container className="my-5">
        <h1>Error</h1>
        <p variant="danger">{error}</p>
        <p>If you need assistance, please contact support.</p>
        <Button
          as={Link}
          to="/tests/bloods/activate"
          variant="primary"
          className="mr-2"
        >
          Previous step
        </Button>
        <Button
          as="a"
          href="https://myhealthchecked.com/contact"
          target="_blank"
          rel="noopener noreferrer"
          variant="outline-primary"
        >
          Contact us
        </Button>
      </Container>
    );
  }

  // Check if the error is related to maintenance (503 status code)
  if (error.details?.status === 503) {
    return (
      <div className="maintenance">
        <Notice title="Maintenance Mode">
          <FontAwesomeIcon icon={faCog} size="6x" className="loading" />
          <p>
            This system is currently in maintenance mode, please check back in a
            few minutes.
          </p>
          <p>
            If you have any questions, please contact <EmailLink />.
          </p>
        </Notice>
      </div>
    );
  }

  // Default case when consent is false, render the fallback error message
  else {
    // Make sure to return the JSX content here
    return (
      <Notice title="Snap!">
        <p>
          Looks like something went wrong, we have been notified about this
          error and will work on a fix as soon as time and coffee supplies
          allow.
        </p>
        <p>
          In the meantime, if you continue to experience errors, please contact{" "}
          <EmailLink />.
        </p>
        <p>
          Here's what we know so far:
          <br />
          <span className="text-dark">{error.message || error.toString()}</span>
        </p>
      </Notice>
    );
  }
}
