import React, { useEffect, useImperativeHandle, forwardRef } from "react";
import { load_script } from "../utils";

// Callback for when reCAPTCHA script is loaded
const loadCallback = "recaptcha_load_callback";

// Callback for when reCAPTCHA is clicked
const clickCallback = "recaptcha_click_callback";

// Recaptcha component to render Google reCAPTCHA
export const Recaptcha = forwardRef(
  (
    { onChange, className, elementId = "google-recaptcha", errors, touched },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      resetRecaptcha() {
        if (window.grecaptcha) {
          window.grecaptcha.reset();
        }
      }
    }));

    useEffect(() => {
      // Function to render reCAPTCHA
      const recaptchaRender = () => {
        window.grecaptcha.render(elementId, {
          sitekey: process.env.REACT_APP_RECAPTCHA_KEY,
          callback: window[clickCallback]
        });
      };

      const onScriptLoad = () => {
        if (window[loadCallback]) {
          // reCAPTCHA has already been loaded
          try {
            recaptchaRender();
          } catch (error) {
            // already rendered is ok, ignore
            if (
              error.message !==
              "reCAPTCHA has already been rendered in this element"
            ) {
              throw error;
            }
          }
          window.grecaptcha.reset();
        } else {
          window[loadCallback] = recaptchaRender;
          load_script(
            `https://www.google.com/recaptcha/api.js?onload=${loadCallback}&render=explicit`
          );
        }
      };

      // Sets click callback to handle reCAPTCHA response
      window[clickCallback] = response => {
        if (onChange) {
          onChange(response);
        }
      };

      // Invokes the script load function
      onScriptLoad();

      // Cleanup function to remove the callback when component unmounts
      return () => {
        window[clickCallback] = undefined;
        window[loadCallback] = undefined;
      };
    }, [elementId, onChange]);

    return (
      <>
        <div className={className} id={elementId} />
        <div>
          {errors && (
            <span className="recaptcha-error">{errors["recaptcha_token"]}</span>
          )}
        </div>
      </>
    );
  }
);
