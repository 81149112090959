import React, { useState } from "react";
import { SplitScreen } from "./components/SplitScreen";
import Page from "./Page";
import SideBar from "./Page/SideBar";
import Toaster from "./stories/Toasts/Toaster";
import { useAuth } from "./components/AuthContext/AuthContext";
import { Container } from "react-bootstrap";
import Flag from "../src/components/Flag"
import ButtonModal from "./stories/Modal/ButtonModal";
import { useNavigate } from "react-router-dom";

function App() {
  const auth = useAuth();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars  
  const [mfa, setMfa] = useState(
    JSON.parse(localStorage.getItem("mfa_enabled")) || null
  );
  const [show, setShow] = useState(!mfa);

  // Handle hiding the MFA modal
  const handleHide = () => {
    localStorage.setItem("defered_mfa", true);
    setShow(false);
  };

  const handleMFA = async () => {
    localStorage.setItem("mfa_enabled", true);
    navigate("/enable-mfa");
    setShow(false);
  };

  return (
    <>
      <main>
        <Flag/>
        <SplitScreen isSidebar={true} rightWeight={4}>
          <SideBar />
          <Container fluid className="mt-2 ms-0">
            <Page />
            {!mfa &&
              !JSON.parse(localStorage.getItem("defered_mfa")) &&
              JSON.parse(localStorage.getItem("isLoggedIn")) && (
                <ButtonModal
                  heading={"Enable MFA"}
                  message={
                    "Multi-factor Authentication (also known as Two Factor Authentication or 2FA) improves your account security by requiring a second means of authentication when logging in."
                  }
                  onClick={handleMFA}
                  onHide={handleHide}
                  show={show}
                />
              )}
          </Container>
          <Toaster newToast={auth?.toast} />
        </SplitScreen>
      </main>
    </>
  );
}

export default App;
