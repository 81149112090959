import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import {
  assessmentStatusUrl,
  phlebotomyAssessmentStatusUrl
} from "../../utils/constants";
import useApis from "../../services/useAPI";
import { Button } from "../../stories/Buttons/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../components/AuthContext/AuthContext";

function prettifyValue(rawValue) {
  let val = rawValue;
  // Join array items into a string
  if (Array.isArray(val)) {
    val = val.join(", ");
  }

  val = String(val || "").replace("_", " ");
  val = toSentenceCase(val);
  return val;
}

// Function to convert text to sentence case (first word capitalized, rest lowercase)
function toSentenceCase(str) {
  return str
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    })
    .join(' ');
}

const lookupTable = {
  "Height & weight": 1,
  Smoking: 1,
  Alcohol: 1,
  "Physical activity": 2,
  "Medical conditions": 3,
  Medications: 4,
  "Menstrual cycle": 5,
  "Sexual health": 5
};
export function SimpleAssessmentSummary() {
  const { callApi, resultGet, isLoading } = useApis();
  const [assessmentData, setAssessmentData] = useState({}); // Initialize state for assessment data
  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const [testType, setTestType] = useState();
  const { test_type, bid, test_kit_id } = useParams();
  const [shData, setShData] = useState();
  const [hormoneData, setHormoneData] = useState();

  const isPhlebotomy = localStorage.getItem("is_phlebotomist");

  useEffect(() => {
    const test_kit_id = location.pathname.split("/")[2];
    if (isPhlebotomy && test_type && bid) {
      callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
    } else if (test_kit_id && !test_type && !bid) {
      callApi(null, `${assessmentStatusUrl}${test_kit_id}`, "GET", true);
    }
  }, []);

  useEffect(() => {
    if (resultGet?.test_kit_details?.test_kit_type?.includes("sex")) {
      const values = resultGet?.assessment_values;
      setShData({
        "Sexual health": {
          "Are you sexually active?": values?.sexual_health?.sexually_active,
          "Your sexual partners are": values?.sexual_health?.partners,
          "How long ago did you last have sex?":
            values?.sexual_health?.recent_sex,
          "Have you had unprotected sex in the past 3 months?":
            values?.sexual_health?.unprotected_sex,
          "Which type of sex was unprotected?":
            values?.sexual_health?.unprotected_sex_type,
          "Have you ever been diagnosed with an STI?":
            values?.sexual_health?.sti_diagnosed,
          "Do you have any symptoms?": values?.sexual_health?.sti_symptoms,
          "Please specify symptoms": values?.sexual_health?.sti_symptoms_description,
          "Do you think you may have been exposed to an STI?":
            values?.sexual_health?.potential_sti_exposure,
          "Please specify exposure": values?.sexual_health?.potential_sti_exposure_type
        }
      });
    } else if (
      resultGet?.test_kit_details?.test_kit_type?.includes("menopause") ||
      resultGet?.test_kit_details?.test_kit_type?.includes("polycystic")
    ) {
      const values = resultGet?.assessment_values;
      setHormoneData({
        "Menstrual cycle": {
          "What is your normal cycle length?": values?.menstrual?.cycle_length,
          "Which day of your cycle was this blood test taken on?":
            values?.menstrual?.day_of_cycle,
          "Are you taking contraception?": values?.menstrual?.contraception
        }
      });
    }
  }, [resultGet]);
  useEffect(() => {
    if (resultGet && isPhlebotomy && test_type && bid) {
      const values = resultGet?.assessment_values;
      const personal_details = resultGet?.phlebotomist_booking_info;
      setAssessmentData({
        "Height & weight": {
          units: values?.body?.units,
          height: values?.body?.height,
          weight: values?.body?.weight
        },
        Smoking: {
          "Do you smoke cigarettes ?": values?.smoking?.smoking,
          "How many cigarettes do you (or did you) smoke each day?":
            values?.smoking?.cigarettes_per_day
        },
        Alcohol: {
          "Do you drink alcohol?": values?.alcohol?.alcohol,
          "How many units do you drink each week?":
            values?.alcohol?.alcohol_units_per_week
        },
        "Physical activity": {
          "Do you do any physical activity?":
            values?.activity?.physical_activity,
          "Light activity, e.g. walking, stretching":
            values?.activity?.light_activity_hours_per_week,
          "Moderate activity, e.g. aerobics, golf":
            values?.activity?.moderate_activity_hours_per_week,
          "Vigorous activity, e.g. running, weight training":
            values?.activity?.vigorous_activity_hours_per_week
        },
        "Medical conditions": {
          "Please tick all that apply": [
            ...(values?.medical_conditions?.condition || [])
          ],
          "Please specify other conditions": values?.medical_conditions?.other_description || ""
        },
        Medications: {
          "Please tick all that apply": [
            ...(values?.medications?.medications || [])
          ],
          "Please specify other medications":
            values.medications?.other_description || ""
        },
        "Personal details": {
          "First name": [personal_details?.first_name],
          "Last name": [personal_details?.last_name],
          "Date of birth": [personal_details?.dob],
          "Sex at birth": [personal_details?.sex],
          "Do you still identify with this gender?": [
            personal_details?.identify_with_gender
          ]
        },
        "Test type": {
          "Test type": [personal_details?.test_type]
        }
      }); // Set the state when data is available
    } else if (resultGet?.assessment_values && !test_type && !bid) {
      const values = resultGet?.assessment_values;
      setAssessmentData({
        "Height & weight": {
          units: values?.body?.units,
          height: values?.body?.height,
          weight: values?.body?.weight
        },
        Smoking: {
          "Do you smoke cigarettes ?": values?.smoking?.smoking,
          "How many cigarettes do you (or did you) smoke each day?":
            values?.smoking?.cigarettes_per_day
        },
        Alcohol: {
          "Do you drink alcohol?": values?.alcohol?.alcohol,
          "How many units do you drink each week?":
            values?.alcohol?.alcohol_units_per_week
        },
        "Physical activity": {
          "Do you do any physical activity?":
            values?.activity?.physical_activity,
          "Light activity, e.g. walking, stretching":
            values?.activity?.light_activity_hours_per_week,
          "Moderate activity, e.g. aerobics, golf":
            values?.activity?.moderate_activity_hours_per_week,
          "Vigorous activity, e.g. running, weight training":
            values?.activity?.vigorous_activity_hours_per_week
        },
        "Medical conditions": {
          "Please tick all that apply": [
            ...(values?.medical_conditions?.condition || [])
          ],
          "Please specify other conditions": values?.medical_conditions?.other_description || ""
        },
        Medications: {
          "Please tick all that apply": [
            ...(values?.medications?.medications || [])
          ],
          "Please specify other medications":
            values.medications?.other_description || ""
        },
        ...shData,
        ...hormoneData
      });
    }
  }, [resultGet, testType, shData, hormoneData]);

  // Render each section
  function renderSection(title, fields) {
    return (
      <Col xs={12} md={6} lg={4} className="mb-4 summary-card">
        <div className="">
          <div className="summary-card-header">
            {" "}
            <h5 className="fs-1-8">{title}</h5>
            {resultGet?.can_be_edited && (
              <Button
                className={"edit-btn"}
                edit={true}
                variant={"outline-primary"}
                label={"Edit"}
                onClick={() => {
                  navigate(
                    `/assessment/${test_kit_id}/${lookupTable[title] + 1}`
                  );
                  auth.setStep(lookupTable[title] + 1);
                  auth.setCompletedSteps(lookupTable[title] + 1);
                  auth.setEditReload(true);
                  if (isPhlebotomy && test_type && bid) {
                    if (title === "Test Type") {
                      navigate(
                        `/bloods/boots/registration/${test_type}?edit=true?bid=${bid}`
                      );
                    } else if (title === "Personal Details") {
                      navigate(
                        `/bloods/boots/${test_type}/confirm-details/${bid}?edit=true`
                      );
                    } else if (resultGet?.assessment_values) {
                      navigate(
                        `/bloods/boots/assessment-form/${test_type}/${bid}`
                      );
                    }
                  } else {
                    navigate(
                      `/assessment/${test_kit_id}/${lookupTable[title] + 1}`
                    );
                  }
                }}
              />
            )}
          </div>
        </div>
        <div>
          {Object.keys(fields).map(f => {
            const prettyValue = prettifyValue(fields[f]);
            if (!prettyValue) return null;

            return (
              <p key={f} className="mt-1">
                <small>
                {f.replace("_", " ").toLowerCase().replace(/^(.)/, (match) => match.toUpperCase())}
                </small>{" "}
                <br />
                <strong className="">{prettyValue}</strong>
              </p>
            );
          })}
        </div>
      </Col>
    );
  }

  return (
    <Row className="assessment-summary">
      <div className="d-flex gap-2 mt-4 fs-1-5">
        <h1>Assessment details</h1>{" "}
      </div>
      <p className="mt-4 fs-1-5 mb-5">
        Here are your health assessment details, related to{" "}
        <strong className="fw-600 text-primary">
          {resultGet?.test_kit_details?.test_kit_name}
        </strong>{" "}
        ({resultGet?.test_kit_details?.test_kit_type}). Our doctor will prepare
        a report based on your answers. You can edit the assessment until the
        sample arrives at our lab.
      </p>
      {Object.keys(assessmentData).length > 0 ? ( // Check if assessmentData has content
        Object.keys(assessmentData).map(section =>
          assessmentData[section]
            ? renderSection(section, assessmentData[section])
            : null
        )
      ) : (

        isLoading ?

          <div className="text-center">
            <Spinner animation="border" variant="info" />
          </div>
          :
          <p> No assessment data available.</p> // Optional: handle no data case
      )}
    </Row>
  );
}
