import React, { useEffect, useState } from "react";
import { useAuth } from '../../components/AuthContext/AuthContext'
import { exerciseFields } from '../../stories/Forms/fields';
import { exerciseSchema } from '../../stories/Forms/validationSchema';
import { Row, Col, Container } from 'react-bootstrap';
import DynamicForm from '../../stories/Forms/DynamicForm';
import {
  assessmentStatusUrl,
  assessmentValidateUrl,
  phlebotomyAssessmentStatusUrl,
} from "../../utils/constants";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useApis from "../../services/useAPI";
import StepProgressBar from "../../stories/ProgressBars/ProgressBar";

let submissionData;
function ExerciseForm() {

  const auth = useAuth()
  const { bid, test_type } = useParams();
  const location = useLocation();
  const navigate = useNavigate()
  const formId = location.pathname.split('/').at(-1)
  const [is_assessment_completed, set_Is_assessment_completed] = useState(false);
  const { setCompletedSteps, setIsCompleted, setStep, totalSteps,
    completedSteps,
    isCompleted } = auth;
  const { test_kit_id } = useParams();

  const [data, setData] = useState({});

  const isPhlebotomy = localStorage.getItem("is_phlebotomist");

  const { callApi, resultGet, resultPost } = useApis();

  const handleAssessmentSubmit = (values) => {
    // Remove keys with undefined values
    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        delete values[key];
      }
    });

    submissionData = {
      activity: {
        physical_activity: values?.physical_activity,
        light_activity_hours_per_week:
          values?.light_activity_hours_per_week || null,
        moderate_activity_hours_per_week:
          values?.moderate_activity_hours_per_week || null,
        vigorous_activity_hours_per_week:
          values?.vigorous_activity_hours_per_week || null,
      },
      step: "activity",
    };

    if (submissionData) {
      callApi(
        {
          user_id: JSON.parse(localStorage.getItem("user_id")),
          ...submissionData
        },
        `${assessmentValidateUrl}`,
        "POST",
        true
      );
    }
    auth.setAllStepData((prevStep) => ({ ...prevStep, ...submissionData }));

    setIsCompleted(1); // Increment the step based on the latest value
    setCompletedSteps(prevSteps => prevSteps + 1); // Use functional update to access the latest value
    setStep(prevStep => prevStep + 1);
  }
  useEffect(() => {
    if (resultGet?.list_of_steps) {
      auth.setTotalSteps(resultGet?.list_of_steps?.includes('phlebotomist_booking_info') ? 6 : 7)
    }
    setData(resultGet?.assessment_values?.activity || auth.allStepData?.activity)

  }, [auth.allStepData, resultGet])

  useEffect(() => {

    if (resultPost) {
      setIsCompleted(parseInt(formId)); // Increment the step based on the latest value
      setCompletedSteps(parseInt(formId) + 1); // Use functional update to access the latest value
      setStep(parseInt(formId));
      navigate(`/assessment/${test_kit_id}/${parseInt(formId) + 1}`, { state: { ...auth.allStepData } })
    }
    if (isPhlebotomy && test_type && bid) {
      callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
    }
    else {
      location?.state?.test_kit_id &&
        localStorage.setItem("test_kit_id", location?.state?.test_kit_id);
      callApi(
        null,
        `${assessmentStatusUrl}${test_kit_id ||
        localStorage.getItem("test_kit_id")}`,
        "GET",
        true
      );
    }
  }, [auth.step, auth.completedSteps, auth.editReload, resultPost]);


  useEffect(() => {
    set_Is_assessment_completed(resultGet?.is_assessment_completed)
  }, [resultGet])

  return (
    <div className="row justify-content-center pt-4 start-assessment-page">
      <div className="col-10">
        <StepProgressBar
          steps={auth.totalSteps}
          currentStep={3}
          completedSteps={2}
          isCompleted={2}
          is_assessment_completed={is_assessment_completed}
        />
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="mt-5">
                <h1 className="mb-4">Exercise</h1>
                <div className="assessment-form">
                  <DynamicForm
                    fields={exerciseFields}
                    blockRefresh={true}
                    modify={true}
                    initialValues={data}
                    validationSchema={exerciseSchema}
                    onSubmit={handleAssessmentSubmit}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default ExerciseForm
