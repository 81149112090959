import React, { useEffect, useState } from "react";
import DynamicForm from "../../stories/Forms/DynamicForm";
import {
  confirmDetailsFields,
  confirmDetailsFieldsDna,
  confirmDetailsFieldsSh,
  phlebotomyConfirmDetailFields
} from "../../stories/Forms/fields";
import useApis from "../../services/useAPI";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { personalDetailsDnaSchema, personalDetailsSchema, personalDetailsShSchema, phlebotomyDetailsShSchema } from "../../stories/Forms/validationSchema";
import GenderWarningModal from "../../stories/Modal/GenderWarningModal";
import { as_title, getTestName, getTestTypeValueFromUrl } from "../../utils";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { phlebotomyAssessmentStatusUrl } from "../../utils/constants";
import { Spinner } from "react-bootstrap";
import { ErrorDisplay } from "../Errors/ErrorDisplay";
import { formattedPhoneNumber } from "../../utils/utils";


/**
* ConfirmDetails component
*
* This component handles the confirmation of personal details for activating test kits.
* It fetches existing personal details, allows the user to upd ate them through a form,
* and submits the data to activate the test kit.
*
* @returns {JSX.Element} The rendered component for confirming personal details.
*/

let allowPost = false;
function ConfirmDetails() {
  const { primaryBarcode } = useParams();
  const [validationSchema, setValidationSchema] = useState(personalDetailsSchema);
  const [genderWarning, setGenderWarning] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [error, setError] = useState("");
  const secondaryBarcodeType = localStorage.getItem("secondary_barcode_type");
  const navigate = useNavigate();
  const auth = useAuth();
  const getApiUrl = `/api/dashboard/activate/personal-details/${primaryBarcode}/`;
  const postApiUrl = `/api/dashboard/activate/personal-details`;
  const postActivatingUrl = `/api/dashboard/activating/`;

  const { callApi, serverError, resultGet, resultPost } = useApis();

  const [fields, setFields] = useState(confirmDetailsFields);
  const { test_type, bid } = useParams();
  const [selectedSex, setSelectedSex] = useState("");
  const [data, setData] = useState()
  const location = useLocation();
  const edit = location.search.includes('edit=true')

  // Checks for isPhlebotomy flag
  const isPhlebotomy = localStorage.getItem("is_phlebotomist");

  useEffect(() => {
    if (resultGet?.type?.startsWith("dna-")) {
      setFields(confirmDetailsFieldsDna);
      setValidationSchema(personalDetailsDnaSchema)
    } else if (secondaryBarcodeType === "urine") {
      setFields(confirmDetailsFieldsSh);
      setValidationSchema(personalDetailsShSchema);
    }

    // Fields for Phlebotomy User Details
    if (isPhlebotomy && test_type) {
      setFields(phlebotomyConfirmDetailFields);
      setValidationSchema(phlebotomyDetailsShSchema)
    }

    // Fetches personal details on mount
    if (!test_type) {
      callApi({}, getApiUrl, "GET");
    }
    if (serverError) setError(serverError);
  }, [secondaryBarcodeType]);

  useEffect(() => {
    if (serverError) {
      setError(serverError);
    }
  }, [serverError])

  useEffect(() => {
    if (isPhlebotomy && test_type && bid)
      callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
  }, [])

  useEffect(() => {
    if (!test_type && !bid) {
      localStorage.setItem("test_kit_id", resultGet?.test_kit_id);
      localStorage.setItem("user_id", resultGet?.customer_id);
      localStorage.setItem(
        "secondary_barcode_type",
        resultGet?.secondary_barcode_type
      );
    }
  }, [resultGet])

  useEffect(() => {
    if (resultPost && !isActivated) {
      const activatingPayload = {
        activation_stage: "personal-details",
        code: primaryBarcode
      };

      // Call API to activate the test kit after successful personal details submission
      callApi(activatingPayload, postActivatingUrl, "POST");

      if (!serverError) {
        auth?.showToast(
          "Confirm user details",
          resultPost?.detail,
          "../assets/mhc.png",
          <ArrowRightCircleFill />
        );
      }

      // Set the state to prevent multiple calls
      setIsActivated(true);

      // Handle the navigation after activation
      if (!genderWarning) {
        if (resultGet?.type?.startsWith("dna")) {
          navigate(`/wellness-collect-sample/${primaryBarcode}`);
        } else if (localStorage.getItem("secondary_barcode_type") === "urine") {
          navigate(`/sh-consent/${primaryBarcode}`);
        } else {
          navigate(`/essential-tips/${primaryBarcode}`);
        }
      }
    }
  }, [resultPost, genderWarning, isActivated]);

  const handleSubmit = (formData) => {

    setData(formData)

    if (isPhlebotomy && test_type) {
      let processedFormData = {
        ...formData,
        bid: bid,
        test_type: test_type,
        phlebotomist_test: true,
      };
      if (!edit) {
        // Process data when not editing
        processedFormData = {
          ...processedFormData,
        };
      } else {
        processedFormData = {
          ...processedFormData,
          edit: true,
        };
      }

      callApi(processedFormData, postApiUrl, "POST");
      auth?.showToast(
        "Confirm user details",
        "User details confirmed!",
        "../assets/mhc.png",
        <ArrowRightCircleFill />
      );

      // Navigates to different paths based on whether it's editing or not
      if (edit) {
        navigate(`/bloods/boots/assessment-details/${test_type}/${bid}`)
      }
      else {
        navigate(`/bloods/boots/assessment/${test_type}/${bid}`)
      }
      return;
    }

    const selectedSex = formData.sex;
    setSelectedSex(selectedSex);

    let processedFormData = {
      ...formData,
      barcode: primaryBarcode,
      ...(resultGet?.type?.startsWith("dna") && { type: "dna" })
    };

    if (resultGet?.type?.startsWith("dna")) {
      delete processedFormData.phone_number_primary;
    }

    if ((resultGet?.type) === "blood-male-sex-health") {
      if (selectedSex === "female") {
        setGenderWarning(true);
        allowPost = true;
      }
    }

    if (
      (resultGet?.type) === "blood-female-sex-health" ||
      (resultGet?.type) === "blood-menopause" ||
      (resultGet?.type) === "blood-polycystic"
    ) {
      if (selectedSex === "male") {
        setGenderWarning(true);
        allowPost = true;
      }
    }

    setIsActivated(false);
    if (!allowPost) {
      callApi(processedFormData, postApiUrl, "POST");
    }
    setError(serverError);
  };

  // Sets initial form values based on fetched data or default values
  const initialFormValues = (isPhlebotomy && bid && test_type && resultGet)
    ? {
      first_name: resultGet?.phlebotomist_booking_info?.first_name || "",
      last_name: resultGet?.phlebotomist_booking_info?.last_name || "",
      dob: resultGet?.phlebotomist_booking_info?.dob || "",
      sex: resultGet?.phlebotomist_booking_info?.sex || "",
      identify_with_gender: resultGet?.phlebotomist_booking_info?.identify_with_gender || "",
      phone_number_primary: formattedPhoneNumber(resultGet?.phlebotomist_booking_info?.phone_number_primary) || ""
    }
    : (!test_type && !bid && resultGet)
      ? {
        first_name: resultGet.first_name || "",
        last_name: resultGet.last_name || "",
        dob: resultGet.dob || "",
        sex: resultGet.sex || "",
        identify_with_gender: resultGet?.identify_with_gender,
        ethnicity: resultGet.ethnicity || "",
        phone_number_primary: formattedPhoneNumber(resultGet.phone_number_primary) || ""
      }
      : {}

  const toggleModal = () => {
    setGenderWarning(!genderWarning);
    if (genderWarning) {
      allowPost = false;
    }
  };

  if ((!resultGet)) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );

  }

  if (error) {
    return <ErrorDisplay error={error} consent={false} />;
  }

  return (
    <div className="row justify-content-center py-5 confirm-details-page">
      <div className="col-10">
        <h1 className="mb-4">Confirm your details</h1>
        {isPhlebotomy === "true" ? (
          <>
            <p className="text-muted">
              <span className="text-dark font-weight-bold">
                {" "}
                Test type:{" "}
                {as_title(
                  `${getTestName(getTestTypeValueFromUrl(resultGet?.type || test_type))} test`
                )}{" "}
              </span>
            </p>
            <p className="text-muted">
              Please fill in the form to activate your booking. You'll be
              able to edit these details until the day of your booking.
            </p>
          </>
        ) : (
          <>
            <p className="text-muted">
              Test type:{" "}
              <span className="text-dark font-weight-bold">{as_title(resultGet?.type)}</span>
            </p>
            <p className="text-muted">
              Before you can activate your test, please submit your details
              below.
            </p>
          </>
        )}
        <DynamicForm
          fields={fields}
          genderButton={true}
          initialValues={data || initialFormValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validationError={error || serverError}
        />
        {genderWarning && (
          <GenderWarningModal
            heading={"Warning"}
            isOpen={genderWarning}
            toggle={toggleModal}
            message={
              <>
                {selectedSex === "female" ? (
                  <>
                    <p>
                      You have selected your gender at birth as <b>female</b>. This test includes the measurement of
                      markers physiologically associated with people born <b>male</b>.
                    </p>
                    <p>
                      This test may not, therefore, be suitable for you. Please
                      contact customer care to exchange or refund your product at{" "}
                      <a href="mailto:care@myhealthchecked.com">
                        care@myhealthchecked.com
                      </a> or 0203 371 3537.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      You have selected your gender at birth as <b>male</b>. This test includes the measurement of
                      markers physiologically associated with people born <b>female</b>.
                    </p>
                    <p>
                      This test may not, therefore, be suitable for you. Please
                      contact customer care to exchange or refund your product at{" "}
                      <a href="mailto:care@myhealthchecked.com">
                        care@myhealthchecked.com
                      </a> or 0203 371 3537.
                    </p>
                  </>
                )}
                <p />
                <p>
                  If you are sure you want to continue, close this window and click Continue.
                </p>
              </>
            }
          />
        )}
      </div>
    </div>
  );
}

export default ConfirmDetails;
