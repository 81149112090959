import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import ReactMarkdown from "react-markdown";
import { Recaptcha } from "../../components/Recaptcha";
import ToggleButtons from "../Buttons/ToggleButtons";
import {
  handleTrackingNumberChange,
  handleChangeWithContext
} from "../../utils";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { Eye, EyeSlash } from "react-bootstrap-icons";

const convert = require("convert-units");

let errorMessage;
function FormInput(props) {
  const { values, touched, errors, setFieldValue } = useFormikContext();
  const [inputValue, setInputValue] = useState(values[props.name] || "");
  const [password, setPassword] = useState(props.value);
  const [showPassword, setShowPassword] = useState(false);
  const auth = useAuth();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // Ensure the input value is in sync with the Formik value
    setInputValue(values[props.name] || "");

    if (auth.unitType.prevUnit !== auth.unitType.currentUnit) {
      if (auth.unitType.currentUnit === "Metric") {
        const metricConverted = {
          height: Math.round(
            convert(values["height"])
              .from("in")
              .to("cm")
          ),
          weight: Math.round(
            convert(values["weight"])
              .from("lb")
              .to("kg")
          )
        };
        if (props.name === "height") {
          setInputValue(metricConverted.height);
          setFieldValue(props.name, metricConverted.height);
        } else if (props.name === "weight") {
          setInputValue(metricConverted.weight);
          setFieldValue(props.name, metricConverted.weight);
        }
      } else if (auth.unitType.currentUnit === "Imperial") {
        const imperialConverted = {
          height: Math.round(
            convert(values["height"])
              .from("cm")
              .to("in")
              .toFixed(1)
          ),
          weight: Math.round(
            convert(values["weight"])
              .from("kg")
              .to("lb")
              .toFixed(1)
          )
        };
        if (props.name === "height") {
          setInputValue(imperialConverted.height);
          setFieldValue(props.name, imperialConverted.height);
        } else if (props.name === "weight") {
          setInputValue(imperialConverted.weight);
          setFieldValue(props.name, imperialConverted.weight);
        }
      }
    }
  }, [auth]);

  if (props?.validationError?.includes("Barcode")) {
    errorMessage = {
      ...(props.name === "code" &&
        props.validationError && {
          [props.name]: props.validationError
        })
    };
  } else if (props?.validationError?.includes("tracking")) {
    errorMessage = {
      ...(props.name === "trackingNumber" &&
        props.validationError && {
          [props.name]: props.validationError
        })
    };
  }

  const combinedErrors = {
    ...errors,
    ...errorMessage,
    code:props.validationError,
  };

  const handleBlur = e => {
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const handleChange = e => {
    if (!props.readOnly && !props.disabled) {
      const newValue = e.target.value;
      setInputValue(newValue);
      // setOriginalValue(newValue); // Update the original value
      props.onChange(e);

      if (props.type === "password") {
        setPassword(newValue);
      }
    }
  };

  return (
    <>
      {!props.hide && (
        <Form.Group className={"form-group"} id={props.controlId || props.name}>
          {props.image && (
            <div>
              <img
                src={props.image}
                alt="Field visual aid"
                className={"form-image mb-2" + props.imgClassname}
              />
            </div>
          )}
          <Form.Label className="labels">
            {props.required ? (
              <>
                {!props.noLabel && props.label}
                <span className="required"></span>
              </>
            ) : (
              !props.noLabel && props.label
            )}
          </Form.Label>

          {props.type === "radio" ? (
            <div>
              {props.choices.map((choice, index) => (
                <Form.Check
                  type="radio"
                  id={`${props.name}-${choice.value}`}
                  name={props.name}
                  value={choice.value}
                  label={choice.label}
                  checked={inputValue === choice.value}
                  onChange={handleChange}
                  required={props.required}
                  isInvalid={props.touched && !!props.error}
                />
              ))}
            </div>
          ) : props.type === "checkbox" && props.name === "consent_steps" ? (
            <Form.Check
              type="checkbox"
              id={`default-${props.id}`}
              label={
                <>
                  <strong>{props.heading}</strong>
                  <br />
                  {props.message && (
                    <small className="form-text text-muted">
                      {props.message}
                    </small>
                  )}
                </>
              }
              checked={props.checked}
              onChange={props.onChange}
              isInvalid={props.validationError || !!errors[props.name]}
            />
          ) : props.type === "checkbox" ? (
            <Form.Check
              key={props.controlId}
              type="checkbox"
              id={props.controlId}
              label={props.label}
              name={props?.name} // Set all checkboxes to 'checked' array
              value={props.label} // Use the 'name' as value to distinguish each checkbox
              onChange={props?.onChange} // Handle change for Formik
              checked={
                props?.values?.checked?.includes(props.label) ||
                values.unprotected_sex_type?.includes(props.label) ||
                values?.potential_sti_exposure_type?.includes(props.label)
              } // Reflect checked status based on initialValues
              className={props.className}
              aria-describedby={props.aria_describedby}
              isInvalid={
                props.validationError ||
                (touched[props.name] && !!errors[props.name])
              }
            />
          ) : props.type === "select" ? (
            <Form.Select
              aria-label="Default select example"
              placeholder={props.placeholder}
              value={props.value}
              isInvalid={props.touched && !!props.error}
              {...props}
              onChange={handleChange}
            >
              <option>{props.placeholder}</option>
              {props.options.map((option, index) => (
                <option key={index} value={option?.value || option}>
                  {option?.label || option}
                </option>
              ))}
            </Form.Select>
          ) : props.type === "toggle-buttons" ? (
            <>
              <div className="form-group toggle-btn-group">
                <ToggleButtons
                  field={props}
                  toggleValueProp={props.toggleValueProp}
                  noLabel={true}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
                <small className="form-text text-muted mt-2">
                  {props.helpText}
                </small>
              </div>
            </>
          ) : (
            <>
              {props.type !== "password" && (
                <Form.Control
                  id={props.controlId || props.name}
                  type={props.type}
                  name={props.name}
                  as={props.as || Form.Control}
                  required={props.required}
                  placeholder={props.placeholder}
                  size={props.size}
                  readOnly={props.readOnly}
                  disabled={props.disabled}
                  min={props.min}
                  max={props.max}
                  maxLength={props.maxLength}
                  value={
                    props.name === "secondary_barcode"
                      ? localStorage.getItem("secondary_barcode_id") || "" // Use an empty string if null
                      : inputValue || values[props.name]
                  }
                  aria-describedby={props.aria_describedby}
                  isInvalid={
                    props.validationError ||
                    (props.touched && !!props.error) ||
                    !!(touched[props.name] && combinedErrors[props.name])
                  }
                  onChange={e => {
                    // Call the existing handle change function
                    handleChangeWithContext(
                      e,
                      props,
                      setInputValue,
                      setFieldValue,
                      handleTrackingNumberChange,
                      auth
                    );
                  }}
                  onBlur={props.onBlur}
                />
              )}
            </>
          )}
          {props.type === "password" && (
            <>
              <Form.Control
                aria-describedby={props.aria_describedby}
                className="password-field"
                as={props?.as || Form.Control}
                disabled={props.disabled}
                isInvalid={props.touched && !!props.error}
                name={props.name}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                required={props.required}
                size={props.size}
                type={showPassword ? "text" : "password"}
                value={password}
              />
              <div
                className="password-input"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                <span>{showPassword ? <Eye /> : <EyeSlash />}</span>
              </div>
            </>
          )}
          {props.type === "recaptcha" && (
            <Recaptcha
              className={props.recaptchaClassName}
              onChange={props.recaptchaOnChange}
              elementId={props.recaptchaElementId}
              errors={errors[props.name]}
              touched={touched}
              isInvalid={
                props.validationError ||
                (touched[props.name] && !!errors[props.name])
              }
            />
          )}
          {combinedErrors[props.name] && !props.group_name && (
            <Form.Control.Feedback type="invalid">
              {combinedErrors[props.name]}
            </Form.Control.Feedback>
          )}
          {props.helpText && (
            <Form.Text
              className={`${props.helpTextClass} help-text`}
              id={props.aria_describedby}
              muted={props.muted}
            >
              {props.helpText && (
                <ReactMarkdown linkTarget={"_blank"}>
                  {props.helpText}
                </ReactMarkdown>
              )}
            </Form.Text>
          )}
        </Form.Group>
      )}
    </>
  );
}

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  as: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  aria_describedby: PropTypes.string,
  helpText: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  helpTextClass: PropTypes.string,
  valueName: PropTypes.string // Added prop for the field name to update on unit change
};

FormInput.defaultProps = {
  as: undefined,
  required: false,
  placeholder: "",
  size: undefined,
  readOnly: false,
  disabled: false,
  aria_describedby: undefined,
  helpText: "",
  image: "",
  helpTextClass: ""
};

export default FormInput;
