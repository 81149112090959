import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

/**
 * Filters an array to only include unique values.
 * Usage: [1, 2, 3, 2, 4].filter(filterUnique) > [1, 2, 3, 4]
 */
export const filterUnique = (value, index, array) =>
  array.indexOf(value) === index;

/**
 * Pauses execution for a specified duration (in milliseconds).
 * Usage: await sleep(1000)
 */
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Converts a string with underscores or hyphens to a title format.
 * Usage: asTitle('what_ever') > 'What Ever'
 */
export const asTitle = s =>
  (s || "")
    .replace(/[ _-]/g, " ")
    .replace(/([ _-]|\b)\w/g, l => l.toUpperCase())
    .replace(/\bdna\b/gi, "DNA");

/**
 * Checks if the user is on a mobile device.
 */
export const onMobile = /mobile|ip(hone|od|ad)|android|blackberry|opera mini/i.test(
  navigator.userAgent
);

/**
 * Custom error class that includes additional details.
 */
export class DetailedError extends Error {
  constructor(message, details) {
    super(message);
    this.name = "DetailedError";
    this.details = details;
  }
}

/**
 * Dynamically loads a script into the document.
 * Usage: await load_script('https://www.example.com/script.js')
 */
export const load_script = url => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${url}"]`)) {
      // Script already loaded
      resolve();
    } else {
      const script = document.createElement("script");
      script.src = url;
      script.onerror = e => reject(e);
      script.onload = () => resolve();
      document.body.appendChild(script);
      setTimeout(() => reject(`Script "${url}" timed out`), 8000);
    }
  });
};

/**
 * Combines multiple class names into a single string, filtering out falsy values.
 * @param {...string} classes - The class names to combine.
 * @returns {string} A single string of class names.
 */
export const combineClasses = (...classes) => classes.filter(c => c).join(" ");

/**
 * Defines test type names and their corresponding labels.
 */
const testTypeNames = [
  { value: "blood-cholesterol", label: "Cholesterol profile" },
  { value: "blood-energy", label: "Energy profile" },
  { value: "blood-erectile", label: "Erectile dysfunction" },
  { value: "blood-general", label: "General health" },
  { value: "blood-heart", label: "Heart profile" },
  { value: "blood-menopause", label: "Menopause profile" },
  { value: "blood-polycystic", label: "Polycystic ovary syndrome (PCOS)" },
  { value: "blood-thyroid", label: "Thyroid profile" },
  { value: "blood-vitamins", label: "Vitamins & minerals profile" },
  { value: "blood-weight", label: "Weight management" },
  { value: "blood-male-sex-health", label: "Male sexual health" },
  { value: "blood-female-sex-health", label: "female sexual health" },
  { value: "dna-weight", label: "Weight management DNA" },
  { value: "dna-glucose", label: "Glucose management DNA" },
  { value: "dna-intolerances", label: "Intolerances & sensitivities management DNA" },
  { value: "dna-heart", label: "Heart management DNA" },
  { value: "dna-vitamins", label: "Vitamins & minerals management DNA" },


];

/**
 * Retrieves the label for a given test type.
 */

export const getTestName = type => {
  const testType = testTypeNames.find(
    testTypeName => testTypeName.value === type
  );
  return testType ? testType.label : "";
};

/**
 * Formats a date string to 'DD/MM/YYYY' format.
 */
export const formatReportedDate = dateString => {
  if (dateString !== " ") {
    return new Date(dateString).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });
  } else {
    return " ";
  }
};

/**
 * Formats a timestamp to 'DD/MM/YYYY' format using moment.js.
 */
export const format_date = ts => moment(ts).format("DD/MM/YYYY");

/**
 * Formats a timestamp to 'Do /MMM /YYYY' format and adds relative time.
 */
const DTF = "Do /MMM /YYYY"; // Define the date format string


/**
 * Formats a date of birth to include age in years.
 */
export const format_dt_ago = ts => {
  const m = moment(ts);
  return `${m.format(DTF)} (${m.fromNow()})`;
};

/**
 * Formats a date of birth to include age in years.
 */
export const format_dt_age = dob => {
  const dobMoment = moment(dob); // Use moment to parse the date of birth
  const currentMoment = moment(); // Get the current date using moment

  // Calculate the age
  const age = currentMoment.diff(dobMoment, "years");

  // Format the date of birth and return the result
  return `${dobMoment.format("DD/MM/YYYY")} (${age} years old)`;
};

/**
 * Converts a date string from MM/DD/YYYY format to YYYY-MM-DD format.
 */
export const formatDob = dateString => {
  const [month, day, year] = dateString.split("/");

  // Check if the input date is valid
  if (!month || !day || !year) {
    throw new Error("Invalid date format. Must be in MM/DD/YYYY format.");
  }

  // Convert to YYYY-MM-DD format
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

/**
 * Formats a barcode by cleaning and segmenting it into a specific pattern.
 */
export const formatBarcode = barcode => {
  let cleanedBarcode = barcode.replace(/[^a-zA-Z\d]/g, ""); // Remove non-alphanumeric characters

  let firstSegment = cleanedBarcode
    .slice(0, 3)
    .toUpperCase()
    .replace(/[^A-Z]/g, ""); // Capitalize and remove non-alphabets
  let secondSegment = cleanedBarcode
    .slice(3, 6)
    .replace(/[^a-zA-Z]/g, "")
    .toLowerCase(); // Remove non-alphabets
  let remainingSegment = cleanedBarcode.slice(6, 10).replace(/\D/g, "");

  if (remainingSegment) {
    return `${firstSegment}-${secondSegment}-${remainingSegment}`;
  } else if (secondSegment) {
    return `${firstSegment}-${secondSegment}`;
  } else {
    return firstSegment;
  }
};

export function formatSecondaryBarcode(barcode) {
  // Split the barcode into two parts: before the dash and after the dash
  const parts = barcode.split('-');

  // Check if the barcode format is correct (i.e., it has exactly two parts)
  if (parts.length === 2) {
    // Convert the second part to lowercase and return the formatted barcode
    return `${parts[0]}-${parts[1].toLowerCase()}-${parts[2]}`;
  }

  // Return the original barcode if it doesn't match the expected format
  return barcode;
}


export const statusMapping = {
  registered: { variant: "primary", label: "Registered" },
  created: { variant: "primary", label: "Registered" },
  processing: { variant: "yellow", label: "Processing" },
  in_transit: { variant: "powder-pink", label: "In Transit" },
  processing_consultation: { variant: "mint", label: "Processing" },
  processing_mint: { variant: "mint", label: "Processing" },
  processing_raspberry: { variant: "raspberry", label: "Processing" },
  results_ready: { variant: "dark-green", label: "Results Ready" },
  activating: { variant: "grey-lilac", label: "Activating" },
  rejected: { variant: "raspberry", label: "Rejected" }
};

export function formatString(input) {
  // Replace hyphens with spaces and capitalize each word
  const words = input.split('-');
  return words
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the first word
      }
      return word.toLowerCase(); // Keep the rest in lowercase
    })
    .join(' '); // Join the words back with spaces               // Join the words back with spaces
}

// Define a function to handle the asynchronous update of meet_details
export const TimeFormatter = (date, time) => {
  return new Promise(resolve => {
    if (!date || !time) {
      resolve("");
      return;
    }

    // Combine date and time into a single string
    const combinedDateTimeStr = `${date}T${time}`;

    // Parse the combined string into a Date object
    const dateObj = new Date(combinedDateTimeStr);

    // Check if the dateObj is valid
    if (isNaN(dateObj.getTime())) {
      resolve("");
      return;
    }

    // Extract components
    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getUTCDate()).padStart(2, "0");
    const hours = String(dateObj.getUTCHours()).padStart(2, "0");
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");
    const milliseconds = String(dateObj.getUTCMilliseconds()).padStart(3, "0");

    // Get timezone offset
    const offset = -dateObj.getTimezoneOffset();
    const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(
      2,
      "0"
    );
    const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, "0");
    const sign = offset >= 0 ? "+" : "-";

    // Construct the formatted datetime string
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${sign}${offsetHours}:${offsetMinutes}`;

    //resolve the promise
    resolve(formattedDateTime);
  });
};

export const TEST_TYPES = {
  "blood-cholesterol": "blood-cholesterol",
  "blood-thyroid": "blood-thyroid",
  "blood-energy": "blood-energy",
  "blood-female-sex-health": "blood-female-sex-health",
  "blood-general": "blood-general",
  "blood-heart": "blood-heart",
  "blood-male-sex-health": "blood-male-sex-health",
  "blood-menopause": "blood-menopause",
  "blood-polycystic": "blood-polycystic",
  "blood-vitamins": "blood-vitamins",
  "blood-weight": "blood-weight",
  "blood-fertility": "blood-fertility",
  "blood-progesterone": "blood-progesterone",
  "weight": "dna-weight",
  "glucose": "dna-glucose",
  "intolerances": "dna-intolerances"
};

export const URL_TO_TEST_TYPE = {
  cholesterol: TEST_TYPES["blood-cholesterol"],
  "energy-profile": TEST_TYPES["blood-energy"],
  "general-health": TEST_TYPES["blood-general"],
  "heart-profile": TEST_TYPES["blood-heart"],
  thyroid: "blood-thyroid", // Ensure this is consistent with your TEST_TYPES
  "vitamins-minerals": TEST_TYPES["blood-vitamins"],
  "weight-management": TEST_TYPES["blood-weight"],
  "weight": TEST_TYPES["dna-weight"],
  "glucose": TEST_TYPES["dna-glucose"],
  "heart": TEST_TYPES["dna-heart"],
  "vitamins": TEST_TYPES["dna-vitamins"],
  "intolerances": TEST_TYPES["dna-intolerances"]
};

export function getTestTypeValueFromUrl(urlValue) {
  // Check if the urlValue is a key in TEST_TYPES
  if (Object.values(TEST_TYPES).includes(urlValue)) {
    return urlValue; // Return as is if it matches a TEST_TYPES value
  }

  // Otherwise, check URL_TO_TEST_TYPE mapping
  return URL_TO_TEST_TYPE[urlValue] || "unknown"; // Return 'unknown' if no match is found
}

export const TEST_TYPE_TO_NAME = {
  [TEST_TYPES["blood-cholesterol"]]: "Cholesterol profile test",
  [TEST_TYPES["blood-energy"]]: "Energy profile test",
  [TEST_TYPES["blood-general"]]: "General health test",
  [TEST_TYPES["blood-heart"]]: "Heart profile test",
  "blood-thyroid": "Thyroid profile test", // Assuming 'blood-thyroid' is not part of TEST_TYPES
  [TEST_TYPES["blood-vitamins"]]: "Vitamins & minerals profile test",
  [TEST_TYPES["blood-weight"]]: "Weight management test",
  [TEST_TYPES["blood-male-sex-health"]]: "Male sexual health",
  [TEST_TYPES["blood-female-sex-health"]]: "Female sexual health",
};

export function getTestNameFromType(testType) {
  return TEST_TYPE_TO_NAME[testType] || "Unknown Test";
}
export const handleChangeWithContext = (e, props, setInputValue, setFieldValue, handleTrackingNumberChange, auth) => {
  const { value } = e.target;


  if (props.name === "code" && props.placeholder === "ABC-abc-1234") {
    const formattedValue = formatBarcode(value);
    setInputValue(formattedValue);
    setFieldValue(props.name, formattedValue);
  } else if (props.name === "code" && props.placeholder === "CONCA12345") {
    const formattedValue = dnaFormatBarcode(value);
    setInputValue(formattedValue);
    setFieldValue(props.name, formattedValue);
  } else if (props.name === "trackingNumber") {
    handleTrackingNumberChange(e, props, setInputValue, setFieldValue);
  } else {
    setInputValue(value); // Update local state
    setFieldValue(props.name, value); // Update Formik's state
  }
};

// Function to convert a string with underscores or dashes to title case
export const as_title = (s) => {
  return (s || "")
    .replace(/[_-]/g, " ") // Replace underscores or dashes with spaces
    .replace(/(?:_|^)\w/g, (l) => l.toUpperCase()) // Capitalize after underscores or word boundaries
    .replace(/\bdna\b/gi, "DNA"); // Replace 'dna' with 'DNA' (case-insensitive)
};


// Function to format the value based on the provided format
export function format_value(value, fmt, choices) {
  if (typeof value === 'boolean' && !fmt) {
    const icon = value ? faCheck : faTimes;
    return <FontAwesomeIcon icon={icon} />;
  }

  if (!value) {
    return <span className="text-muted">&mdash;</span>;
  }

  if (choices) {
    const selected = choices.find(c => c.value === value);
    return selected ? selected.label : value;
  }

  switch (fmt) {
    case "datetime":
      return format_dt_ago(value);
    case "date":
      return format_date(value);
    case "inline-code":
      return <code className="inline-code">{value}</code>;
    case "as-title":
      return as_title(value);
    case "as-country":
      if (window.country_lookup) {
        return window.country_lookup[value] || value;
      }
      return value;
    case "money":
      return `£${value.toFixed(2)}`;
    default:
      if (typeof fmt === "function") {
        return fmt(value);
      } else {
        throw Error(`Unknown fmt "${fmt}"`);
      }
  }
}


// Function to format the phone number
const formatPhoneNumber = input => {
  if (input === "") {
    return ""; // Allow empty input
  }

  // Remove non-numeric characters except +
  let formattedNumber = input.replace(/[^\d]/g, ""); // Only allow digits

  // Automatically add +44 if not present
  if (!formattedNumber.startsWith("44") && formattedNumber.length > 0) {
    formattedNumber = "44" + formattedNumber; // Prepend 44 if not present
  }

  // Format the number to +44 XXXX XXXX XXXX
  if (formattedNumber.startsWith("44")) {
    const prefix = "+44 ";
    const areaCode = formattedNumber.substring(2, 6); // 4444
    const mainNumber = formattedNumber.substring(6, 13); // Remaining digits
    formattedNumber = prefix + areaCode + (mainNumber ? " " + mainNumber : "");
  }

  return formattedNumber;
};

export const handleTrackingNumberChange = (e, props, setInputValue, setFieldValue) => {
  if (!props.readOnly && !props.disabled) {
    let value = e.target.value;
    let cleanedValue = value.replace(/[^a-zA-Z\d]/g, "");

    let firstSegment = cleanedValue
      .slice(0, 2)
      .replace(/[^A-Za-z]/g, "")
      .toUpperCase();
    let secondSegment = cleanedValue.slice(2, 6).replace(/\D/g, "");
    let thirdSegment = cleanedValue.slice(6, 10).replace(/\D/g, "");
    let fourthSegmentPart1 = cleanedValue.slice(10, 11).replace(/\D/g, "");
    let fourthSegmentPart2 = cleanedValue
      .slice(11, 13)
      .replace(/[^A-Za-z]/g, "")
      .toUpperCase();

    let formattedValue = firstSegment;
    if (secondSegment) formattedValue += ` ${secondSegment}`;
    if (thirdSegment) formattedValue += ` ${thirdSegment}`;
    if (fourthSegmentPart1 || fourthSegmentPart2) {
      formattedValue += ` ${fourthSegmentPart1}${fourthSegmentPart2}`;
    }

    setInputValue(formattedValue); // Update local state
    setFieldValue(props.name, formattedValue); // Update Formik's state
  }
};

function dnaFormatBarcode(value) {
  // Remove any non-alphabetic characters from the first part
  const letters = value
    .slice(0, 5)
    .replace(/[^A-Za-z]/g, "")
    .toUpperCase();

  // Remove any non-numeric characters from the second part
  const digits = value.slice(5, 10).replace(/\D/g, "");

  // Return formatted value
  return letters + digits;
}

export const YoutubeEmbed = ({ embedId }) => (
  <div className="ratio ratio-16x9">
    <iframe
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

/**
 * handleValidation function
 *
 * Validates the entered values based on the current path. It checks if the entered secondary barcode
 * and its type are appropriate for the selected test type (blood, sexual health, or DNA).
 *
 * @param {Object} values - The form values entered by the user.
 * @returns {string} An error message if validation fails, or an empty string if it passes.
 */

export const handleValidation = (values, currentPath) => {
  if (currentPath.includes("/enter-barcode/blood")) {
    if (values.secondary_barcode_id) {
      if (values.secondary_barcode_type !== "lavender") {
        return "To proceed with activating your Blood Test Kit, please ensure that you have selected the appropriate option on the activation page.";
      }
    } else if (
      values.secondary_barcode_type &&
      values.secondary_barcode_type !== "lavender"
    ) {
      return "To proceed with activating your Blood Test Kit, please ensure that you have selected the appropriate option on the activation page.";
    }
  } else if (currentPath.includes("/enter-barcode/sh")) {
    if (!values.secondary_barcode_id || values.secondary_barcode_type !== "urine") {
      return "To proceed with activating your Sexual Health Test Kit, please ensure that you have selected the appropriate option on the activation page.";
    }
  } else if (currentPath.includes("/enter-barcode/dna")) {
    if (values.secondary_barcode_id) {
      return "Secondary_barcode must be null.";
    }
  }
  return "";
};

export const format_date_reports = ts => moment(ts).format("Do MMM YYYY");

/**
 * Returns an object containing the 'min' and 'max' date attributes for a date picker.
 * The 'min' will be 15 days ago from today, and the 'max' will be today's date.
 * @returns {Object} - Object with min and max date in 'YYYY-MM-DD' format.
 */
export function getDateRangeForPicker() {
  // Get today's date
  const today = new Date();

  // Format today's date to 'YYYY-MM-DD'
  const todayFormatted = today.toISOString().split('T')[0];

  // Get the date 15 days ago
  const fifteenDaysAgo = new Date();
  fifteenDaysAgo.setDate(today.getDate() - 14);

  // Format the date 15 days ago to 'YYYY-MM-DD'
  const fifteenDaysAgoFormatted = fifteenDaysAgo.toISOString().split('T')[0];

  return {
    min: fifteenDaysAgoFormatted,  // Allow only dates from 15 days ago
    max: todayFormatted,           // Disable future dates (up to today)
  };
}

export const today = new Date(); // Format today's date to YYYY-MM-DD
export const todayFormatted = today.toISOString().split('T')[0];
const fifteenDaysAgo = new Date();
fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 14);
export const previousFifteenDays = fifteenDaysAgo.toISOString().split('T')[0]; // Format the date 15 days ago