import React, { useEffect, useCallback, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useAuth } from '../../components/AuthContext/AuthContext';
import { Button } from '../../stories/Buttons/Button';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StepProgressBar from "../../stories/ProgressBars/ProgressBar";
import useApis from '../../services/useAPI';
import { assessmentStatusUrl } from '../../utils/constants';

function StartPage() {
    const auth = useAuth();
    const params = useParams();
    const { test_kit_id } = params
    const location = useLocation()
    const formId = location.pathname.split('/').at(-1)
    const [completedSteps, setCompletedSteps] = useState()
    const [isCompleted, setIsCompleted] = useState()
    const [step, setStep] = useState()
    const { totalSteps, setTotalSteps } = auth;
    const [is_assessment_completed, set_Is_assessment_completed] = useState(false);

    const navigate = useNavigate()
    const { callApi, resultGet } = useApis();

    const handleNextStep = useCallback(() => {
        setIsCompleted(1); // Increment the step based on the latest value
        setCompletedSteps(parseInt(formId) + 1); // Use functional update to access the latest value
        setStep(parseInt(formId));
        navigate(`/assessment/${test_kit_id}/${parseInt(formId) + 1}`)
    }, [formId]);


    useEffect(() => {
        callApi(
            null,
            `${assessmentStatusUrl}${test_kit_id ||
            localStorage.getItem("test_kit_id")}`,
            "GET",
            true
        );
    }, [isCompleted])

    useEffect(() => {
        if (resultGet?.list_of_steps) {
            setTotalSteps(resultGet?.list_of_steps?.includes('phlebotomist_booking_info') ? 6 : 7)
        }
    }, [resultGet])

    useEffect(() => {
        set_Is_assessment_completed(resultGet?.is_assessment_completed)
    }, [resultGet])

    return (
        <div className="row justify-content-center pt-4 start-assessment-page">
            <div className="col-10">
                <StepProgressBar
                    steps={totalSteps}
                    currentStep={formId}
                    completedSteps={completedSteps}
                    isCompleted={isCompleted}
                    is_assessment_completed={is_assessment_completed}
                />
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8}>

                            <div className="mt-5">
                                <h1 className="mb-4">Health assessment</h1>
                                <p className="lead">
                                    You must complete the Health Assessment in order for you to
                                    receive your results and doctor's report. The information you
                                    submit will be reviewed by our doctors to ensure accurate
                                    assessments of your test results.
                                </p>
                                <p className="text-muted">
                                    This will take up to 2 minutes to complete.
                                </p>
                                <div className="d-flex justify-content-end">
                                    <Button
                                        className="btn ms-auto btn-primary"
                                        label="Continue"
                                        onClick={() => handleNextStep()}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default StartPage
