import React, { useEffect } from "react";
import ToggleButtons from "../../stories/Buttons/ToggleButtons";
import { Col, Row, Container } from "react-bootstrap";
import { Formik, Form } from "formik";
import { Button } from "../../stories/Buttons/Button";
import useApis from "../../services/useAPI";
import { useNavigate, useParams } from "react-router-dom";
import { DNAAsstFields } from "../../stories/Forms/fields";
import { dnaAssessmentSchema } from "../../stories/Forms/validationSchema";

const WellnessAssessment = () => {

  const params = useParams()
  const {test_kit_id} = params
  const url = `/api/dashboard/assessment/dna/${test_kit_id}`;
  const navigate = useNavigate()

  
  const { callApi, resultPost } = useApis();

  const handleSubmitNew = values => {
    const data = {
      dairy: values.dairy,
      nuts: values.nuts,
      gluten: values.gluten,
      shellfish: values.shellfish,
      oily_fish: values.oily_fish,
      eggs: values.eggs,
      pork: values.pork,
      poultry: values.poultry,
      alcohol: values.alcohol,
      red_meat: values.red_meat
    };

    callApi(data, url, "POST");

  };

  useEffect(()=>{
if (resultPost){
  navigate('/')
}
  },[resultPost])
  const initialValues = DNAAsstFields.reduce((acc, field) => {
    acc[field.name] = ""; // Default value for each field
    return acc;
  }, {});

  return (
    <div className="row justify-content-center pt-4 wellness-assessment-page">
      <div className="mt-3 p-0 col-10">
        <h1 className="mb-4">Wellness DNA assessment</h1>
        <div className="text-dark font-weight-bold">
          Please provide some basic details about yourself. We'll use this to
          customize your reports.
        </div>
        <p className="std-top-md">
          For the following please tell us which you can't eat (allergy or
          religious restriction), don't eat, or those you eat.
        </p>

        <Formik initialValues={initialValues} onSubmit={handleSubmitNew} validationSchema={dnaAssessmentSchema}>
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <Container fluid className="p-0">
                <Row>
                  {DNAAsstFields.map((field, index) => (
                    <Col sm={6} md={6} xl={4} xs={12} key={index}>
                      <div className="std-top-md">
                        <div className="form-group">
                        <ToggleButtons
                          field={field}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                        />
                        </div>
                
                      </div>
              
                    </Col>
                  ))}

                  <Col
                    sm={12}
                    md={12}
                    xl={12}
                    xs={12}
                    className="d-flex justify-content-end"
                  >
                    <div className="btn-group wellness-assessment-btn-container">
                      <Button
                        label={"Skip"}
                        size={"small"}
                        className={"capsule-left-button btn-secondary"}
                        onClick={()=> navigate('/')}/>
                      <Button
                        label={"Submit"}
                        type="submit"
                        size={"small"}
                        className={"capsule-right-button"}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WellnessAssessment;
