import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { testTypeFields } from "../../stories/Forms/fields";
import DynamicForm from "../../stories/Forms/DynamicForm";
import useApis from "../../services/useAPI";
import { confirmTestTypeUrl } from "../../utils/constants";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import { getTestTypeValueFromUrl } from "../../utils";

let newData;
let bid;

const ConfirmTestType = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { callApi, resultPost } = useApis();
  const { test_type } = useParams();
  const edit = location.search.includes("edit=true");
  edit && (bid = location.search?.split('?')[2].split('=')[1])
  const [formData, setFormData] = useState({ test_type: test_type || "" });

  // Updates the form data based on the test_type
  useEffect(() => {
    if (test_type) {
      setFormData({ test_type: getTestTypeValueFromUrl(test_type) });
    }
  }, [test_type]);

  // Handles form submission
  const handleSubmit = async data => {
    if (!edit) {
      // If not editing, uses formData or test_type value from URL
      const test_type_data = data.test_type
        ? data
        : formData || getTestTypeValueFromUrl(test_type);
      newData = {
        ...test_type_data
      };
    } else {
      // If editing, includes additional bid and edit flags
      newData = {
        bid: bid,
        test_type: data.test_type
          ? data?.test_type
          : formData?.test_type || getTestTypeValueFromUrl(test_type),
        edit: true
      };
    }
    // Prepares payload and make API call
    const payload = {
      ...newData
    };
    await callApi(payload, confirmTestTypeUrl, "POST");
  };

  // Handles API response and navigation
  useEffect(() => {
    if (resultPost && resultPost.status === "success") {
      localStorage.setItem(
        "test_type",
        newData?.test_type ||
          formData?.test_type ||
          getTestTypeValueFromUrl(test_type)
      );
      auth?.showToast(
        "Test type confirmation",
        "Test type confirmed!",
        "../assets/mhc.png",
        <ArrowRightCircleFill />
      );

      // Navigates based on whether the form is in edit mode
      if (edit) {
        navigate(`/bloods/boots/assessment-details/${test_type}/${bid}`);
      } else {
        navigate(
          `/bloods/boots/${newData?.test_type ||
            formData?.test_type}/booking/consent/${resultPost.bid}`
        );
      }
    }
  }, [resultPost, navigate]);

  return (
    <>
      <div className="confirm-test-form">
        <div className="row justify-content-center pt-4 dashboard-page">
          <div className="col-10">
            <p className="mb-4">
              Please confirm the test you selected on the Boots website. We will
              prepare it ahead of your arrival for your in-store appointment.
            </p>
            <DynamicForm
              fields={testTypeFields}
              initialValues={formData}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmTestType;
