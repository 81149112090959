import React, { useEffect } from "react";
import useApis from "../../services/useAPI";
import { useAuth } from "../../components/AuthContext/AuthContext";
import DynamicForm from "../../stories/Forms/DynamicForm";
import { useNavigate } from "react-router-dom";
import { BoxArrowInRight } from "react-bootstrap-icons";
import { setMfaTimestamp } from "./MFAExpiry";
export const confirmMfaFields = [
  {
    controlId: "2",
    label: "Confirm OTP",
    type: "password",
    name: "otp",
    as: "",
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group mt-4"
  },

  {
    controlId: "3",
    label: "Confirm OTP",
    type: "submit",
    size: "md",
    variant: "primary",
    className: "btn-login mt-4"
  }
];

const ConfirmOtp = () => {
  const { callApi, resultPost } = useApis();
  const auth = useAuth();
  const navigate = useNavigate();
  const url = `/api/dashboard/send-login-otp`;
  const url2 = `/api/dashboard/verify-login-otp`;

  const handleMFASubmit = async values => {
    const data = {
      otp: values.otp, // Use values.otp instead of data.otp
      user_id: JSON.parse(localStorage.getItem("user_id"))
    };

    callApi(data, url2, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json"
    });
  };

  useEffect(() => {
    callApi({ user_id: JSON.parse(localStorage.getItem("user_id")), role: "customer" }, url, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json"
    });
  }, []);

  useEffect(() => {
    if (resultPost?.data?.access_token) {
      setMfaTimestamp()
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("access_token", resultPost.data.access_token);
      localStorage.setItem("refresh_token", resultPost.data.refresh_token);
      localStorage.setItem("username", resultPost.data.fullname);
      localStorage.setItem("email", resultPost.data.email);
      localStorage.setItem("role", resultPost.data.role);
      localStorage.setItem("user_id", resultPost.data.id);

      navigate("/");
      // Shows success message if user logs in successfully
      auth?.showToast(
        "Logged in",
        "Logged in successfully!",
        "../assets/mhc.png",
        <BoxArrowInRight />
      );
    }
  }, [resultPost]);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="py-4">
            <h1>ConfirmOtp</h1>
            <p className="my-4">
              Please confirm the code we sent to your phone to complete your MFA
              setup.
            </p>
            <DynamicForm fields={confirmMfaFields} onSubmit={handleMFASubmit} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmOtp;
