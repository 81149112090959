import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import DynamicForm from "../../stories/Forms/DynamicForm";
import {
  bodyFields,
  medicalConditionFields,
  exerciseFields,
  medicationFields,
  menstrualCycleFields,
  sexualHealthFields
} from "../../stories/Forms/fields";
import {
  bodyAssessmentSchema,
  shSchema,
  menstrualSchema,
  exerciseSchema,
  medicalConditionsSchema,
  medicationsSchema
} from "../../stories/Forms/validationSchema";
import { Button } from "../../stories/Buttons/Button";
import CheckboxFormGroup from "../../stories/Forms/CheckboxFormGroup";

const SwitchAssessmentStep = ({
  step,
  handleNextStep,
  handleAssessmentSubmit,
  isShTest,
  isHormoneTest,
  initialValues,
  handlePreviousStep,
  finalSubmit
}) => {
  
  switch (step) {
    case 1:
      return (
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="mt-5">
                <h1 className="mb-4">Health Assessment</h1>
                <p className="lead">
                  You must complete the Health Assessment in order for you to
                  receive your results and doctor's report. The information you
                  submit will be reviewed by our doctors to ensure accurate
                  assessments of your test results.
                </p>
                <p className="text-muted">
                  This will take up to 2 minutes to complete.
                </p>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn ms-auto btn-primary"
                    label="Continue"
                    onClick={() => handleNextStep()}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      );
    case 2:
      return (
        <>
          <Container>
            <Row className="justify-content-center">
              <Col md={8}>
                <div className="mt-5">
                  <h1 className="mb-4">Height and weight</h1>
                  <div className="assessment-form">
                    <Col xs={12}>
                      <DynamicForm
                        className="mb-4"
                        fields={bodyFields}
                        onSubmit={handleAssessmentSubmit}
                        modify={true}
                        blockRefresh={true}
                        modifyFields={true}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={bodyAssessmentSchema}
                        initialValues={{
                          ...initialValues?.body,
                          ...initialValues?.smoking,
                          ...initialValues?.alcohol
                        }}
                      />
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      );
    case 3:
      return (
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="mt-5">
                <h1 className="mb-4">Exercise</h1>
                <div className="assessment-form">
                  <DynamicForm
                    fields={exerciseFields}
                    blockRefresh={true}
                    modify={true}
                    initialValues={initialValues?.activity}
                    validationSchema={exerciseSchema}
                    onSubmit={handleAssessmentSubmit}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      );
    case 4:
      return (
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="mt-5">
                <h1 className="mb-4">Medical conditions</h1>
                <CheckboxFormGroup
                  className="assessment-form"
                  fields={medicalConditionFields}
                  blockRefresh={true}
                  initialValues={{ ...initialValues?.medical_conditions }}
                  type={"medical_conditions"}
                  validationSchema={medicalConditionsSchema}
                  onSubmit={handleAssessmentSubmit}
                />
              </div>
            </Col>
          </Row>
        </Container>
      );
    case 5:
      return (
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="mt-5">
                <h1 className="mb-4">Medication</h1>
                <p>
                  Are you taking any regular medication? Please tick all that
                  apply:
                </p>

                <CheckboxFormGroup
                  className="assessment-form"
                  initialValues={{ ...initialValues?.medications }}
                  blockRefresh={true}
                  fields={medicationFields}
                  validationSchema={medicationsSchema}
                  type={"medications"}
                  onSubmit={handleAssessmentSubmit}
                />
              </div>
            </Col>
          </Row>
        </Container>
      );
    case 6:
      return isShTest || isHormoneTest ? (
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="mt-5">
                <h1 className="mb-4">
                  {isShTest ? "Sexual health" : "Menstrual cycle"}
                </h1>
                <div className="assessment-form">
                  <DynamicForm
                    fields={
                      isShTest
                        ? sexualHealthFields
                        : isHormoneTest && menstrualCycleFields
                    }
                    validationSchema={isShTest ? shSchema : menstrualSchema}
                    blockRefresh={true}
                    modify={true}
                    initialValues={
                      isShTest
                        ? initialValues?.sexual
                        : initialValues?.menstrual
                    }
                    onSubmit={handleAssessmentSubmit}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row className="justify-content-center mt-4">
            <Col md={8}>
              <div className="assessment-form">
                <h1>Assessment summary </h1>
                <p>
                  Our doctor will review your test results in the context of
                  this Health Assessment. Please check that all details are
                  correct before submitting, as you will not be able to edit
                  your answers after submission.
                </p>
                <div className={`d-flex justify-content-between align-items-center`}>
                  <Button
                    className="btn btn-gray float-start"
                    variant={'secondary'}
                    label="Previous step"
                    onClick={() => handlePreviousStep()}
                  />
                  <Button
                    className="btn  btn-primary float-end"
                    label="Assessment Submit"
                    onClick={() => finalSubmit()}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      );
  }
};

export default SwitchAssessmentStep;
