import React, { useEffect, useState } from "react";
import "../../../../assets/scss/_marker-scale.scss";
import { renderRangeLabel } from "../../../../utils/reportUtils";

/**
 * BoundaryLabels component
 * This component renders labels for the boundaries of a marker scale.
 * It displays low, normal, and high boundary labels with optional styles
 * based on the provided props.
 * @returns {JSX.Element} The rendered boundary labels component.
 */

const BoundaryLabels = ({
  showLowBoundary,
  leftBarSize,
  barSize,
  normalBoundaryLabel,
  highBoundaryLabel,
  decision_type,
  type,
  lower_boundary,
  upper_boundary,
  boundary,
}) => {
  const [isWidth, setIsWidth] = useState();

  useEffect(() => {
    const calculatedWidth = getWidth(type);
    setIsWidth(calculatedWidth);
  }, [type]);

  function getWidth(type) {
    const words = type.split("-");
    if (words.length === 2) {
      return 50;
    } else if (words.length === 3) {
      return 33.33;
    }
    return 0; // Default if other cases appear
  }

  return (
    <div className="markerScale__labels">
      {type !== "normal-high" && showLowBoundary && (
        <div
          style={{ width: `${isWidth || 0}%` }} // Use a fallback value if isWidth is undefined
          className="markerScale__label"
        >
          Low
        </div>
      )}
      <div style={{ width: `${isWidth}%` }} className="markerScale__label">
        {normalBoundaryLabel}
        <div className="markerScale__label-range">
          {renderRangeLabel(
            decision_type,
            type,
            lower_boundary,
            upper_boundary,
            boundary
          )}
        </div>
      </div>
      {type !== "low-normal" && (
        <div style={{ width: `${isWidth || 0}%` }} className="markerScale__label">
          {highBoundaryLabel}
        </div>
      )}
    </div>
  );
};

export default BoundaryLabels;
