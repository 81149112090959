import React from "react";
import { Markdown } from "@storybook/blocks";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Displays modal with buttons for user actions
function ButtonModal(props) {
  return (
    <>
      <Modal onHide={props.onHide} show={props.show}>
        <Modal.Header closeButton>
          <Modal.Title>{props.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Markdown>{props.message}</Markdown>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} variant="error" color="danger">
            Enable Later
          </Button>
          <Button onClick={props.onClick} variant="primary">
            Enable MFA
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ButtonModal;
