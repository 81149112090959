import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Container, Form, Alert } from "react-bootstrap";
import PropTypes from "prop-types";
import useApis from "../../services/useAPI";
import ReactMarkdown from "react-markdown";
import { phlebotomyPostConsentUrl } from "../../utils/constants";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import { ErrorDisplay } from "../Errors/ErrorDisplay";

/**
 * Consent component for displaying and submitting the consent form.
 * This component fetches the consent data and allows the user to provide consent for a test or procedure.
 * It handles form submission and includes validation for consent and terms acceptance.
 * 
 **/

let typeResponse;
export function Consent() {
  const { bid } = useParams();
  const [error, setError] = useState("");
  const [consentData, setConsentData] = useState(null);
  const [checkedConsent, setCheckedConsent] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  // const [typeResult, setTypeResult] = useState();
  const { primaryBarcode } = useParams();
  const postApiUrl = "/api/dashboard/consent/";
  const { test_type } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const { callApi, isLoading, serverError, resultGet, resultPost } = useApis();
  const typeUrl = `/api/dashboard/check-test/${primaryBarcode}/`;

  const isPhlebotomy = localStorage.getItem("is_phlebotomist");

  useEffect(() => {
    const fetchTypeAndConsentData = async () => {
      try {
        if(!test_type){
           typeResponse = await callApi({}, typeUrl, "GET");
        }

        const fetchedType = typeResponse?.type;

        let apiUrl;
        if (isPhlebotomy && test_type) {
          apiUrl = `/api/dashboard/booking/consent/${bid}`;
        } else {
          apiUrl = fetchedType?.startsWith("dna")
            ? `/api/dashboard/consent/${primaryBarcode}/processing`
            : `/api/dashboard/consent/${primaryBarcode}`;
        }

        await callApi({}, apiUrl, "GET");

        if (serverError) setError(serverError);
      } catch (err) {
        setError("Failed to fetch type or consent data.");
      }
    };

    fetchTypeAndConsentData();
  }, [primaryBarcode, bid, test_type]);

  useEffect(() => {
    if (resultGet) setConsentData(resultGet);
  }, [resultGet]);

  useEffect(() => {
    if (resultPost) {
      navigate(`/confirm-details/${primaryBarcode}`);
    } else if (serverError) {
      setError(serverError);
    }
  }, [resultPost, serverError]);


  const handleSubmit = async e => {
    e.preventDefault();
    setError("");
    if (isPhlebotomy && test_type) {
      const payload = {
        bid: consentData.bid,
        consent_given: true,
        consent_type: consentData.consent_type,
      };

      callApi(payload, phlebotomyPostConsentUrl, "POST");
      auth?.showToast(
        "Consent Form",
        "Consent saved!",
        "../assets/mhc.png",
        <ArrowRightCircleFill />
      );
      navigate(`/bloods/boots/${test_type}/confirm-details/${bid}`);
    }
    else {
      const payload = {
        test_kit_id: consentData?.test_kit_id,
        consent_given: true,
        ...(consentData?.type?.startsWith("dna") && { consent_type: "processing" })
      };
      await callApi(payload, postApiUrl, "POST");
    }
  };

  // if (typeResult) {
  //   return (
  //     <ErrorDisplay
  //       alreadyActivated={true}
  //     />
  //   );
  // }

  if (error) {
    return <ErrorDisplay error={error} consent={true} />;
  }

  if (!consentData) {
    return (
      <Container className="my-5">
        <div>Loading...</div>
      </Container>
    );
  }

  return (
    <div className="row pt-4 consent-page justify-content-center">
      <Form onSubmit={handleSubmit} className="mt-3 p-0 col-10">
        <h1 className="mb-4">Declaration of consent</h1>
        <div className="consent-form-middle">
          <ReactMarkdown>
            {consentData?.consent_text}
          </ReactMarkdown>
        </div>
        <div className="consent-form-footer">
          <h3>Please confirm consent to the above conditions.</h3>
          <Form.Group controlId="consentGiven" className="mb-2">
            <Form.Check
              type="checkbox"
              label="I have read the above information and give consent"
              checked={checkedConsent}
              onChange={e => setCheckedConsent(e.target.checked)}
              required
            />
          </Form.Group>
          <Form.Group controlId="termsAccepted">
            <Form.Check
              type="checkbox"
              label={
                <>
                  I have read the{" "}
                  <a
                    href="https://myhealthchecked.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  and agree to the{" "}
                  <a
                    href="https://myhealthchecked.com/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </>
              }
              checked={checkedTerms}
              onChange={e => setCheckedTerms(e.target.checked)}
              className="mb-2"
              required
            />
          </Form.Group>
          {error && <Alert variant="danger">{error}</Alert>}
          <Button
            variant="primary"
            type="submit"
            className="mt-3"
            disabled={isLoading}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}

Consent.propTypes = {
  match: PropTypes.object
};
