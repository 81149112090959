import React, { useEffect, useState } from "react";
import { LinkCard } from "../stories/cards/LinkCard";
import Buy from "../assets/icons/Platform/Buy_tests.svg";
import PCR from "../assets/icons/Platform/PCR_tests.svg";
import { as_title, format_date, iconHelper } from "../utils/utils";
import { myResultUrl } from "../utils/constants";
import useApis from "../services/useAPI";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const TestList = () => {
  const { id } = useParams();
  const [testData, setTestData] = useState([]);
  const { callApi, isLoading, serverError, resultGet } = useApis();

  useEffect(() => {
    const fetchTestData = async () => {
      try {
        await callApi(null, myResultUrl, "GET");
      } catch (error) {
        console.error("API error:", error);
      }
    };
    fetchTestData();
  }, [id]);

  useEffect(() => {
    if (resultGet?.success) {
      setTestData(resultGet.data);
    }
  }, [resultGet]);

  if (isLoading)
  {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );

  }

  // Error state
  if (serverError) {
    return <p className="text-muted">{serverError}</p>;
  }

  return (
    <div className="pt-sm-4 mt-4 mb-4">
      <div className="container activate-box-wrapper">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-left py-4">My results</h1>

            {testData?.length === 0 && !isLoading ? (
              <div>
                <p className="text-muted">You don't have any tests yet.</p>
                <LinkCard
                  to="https://myhealthchecked.com/our-tests"
                  image={Buy}
                  title="Buy Tests"
                  button_text="Buy Tests"
                  button_append_class="my-button-class"
                >
                  <p className="text-muted">
                    If you don't yet have a test, you can buy one now.
                  </p>
                </LinkCard>
              </div>
            ) : (
              <div>
                <p className="text-muted">
                  Below is a summary of your current tests.
                </p>
                {testData?.map((test, i) => (
                  <div key={i}>
                    <SummaryCard test={test} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Get label for secondary card button, depending on assessment status
function getAssessmentButtonDetails(
  assessment_id,
  can_edit_assessment,
  type,
  testID,
  status
) {
  if (
    status?.toLowerCase() === "error" ||
    status?.toLowerCase() === "rejected"
  ) {
    return {
      label: type?.startsWith("dna-")
        ? "Assessment"
        : assessment_id
          ? "Edit assessment"
          : "Fill assessment",
      link: `#`,
      appendClass: "disabled"
    };
  }

  // Fixed link for Wellness tests
  if (type?.startsWith("dna-")) {
    return {
      label: "Assessment",
      link: `/assessment/wellness/${testID}`,
      appendClass: ""
    };
  }

  // If no assessment and it can't be edited = no label
  if (!assessment_id && !can_edit_assessment) {
    return { label: "Fill assessment", link: `#`, appendClass: "disabled" };
  }

  // If has read-only assessment, view it - Note: the API uses the testID (test-kit) ID
  if (assessment_id && !can_edit_assessment) {
    return {
      label: "View assessment",
      link: type === "" ? `/assessment/${testID}/1` : `/assessment-details/${testID}`,
      appendClass: ""
    };
  }

  // If has filled assessment and can edit, offer editing - Note: the API uses the testID (test-kit) ID
  if (assessment_id && can_edit_assessment) {
    return {
      label: "Edit assessment",
      link: `/assessment-details/${testID}`,
      appendClass: ""
    };
  }

  // Note: the API uses the testID (test-kit) ID
  return {
    label: "Fill assessment",
    link: `/assessment/${testID}/1`,
    appendClass: ""
  };
}

const SummaryCard = ({ test }) => {
  const {
    id,
    detail_link,
    type,
    type_name,
    booking_reference,
    primary_barcode,
    report_id,
    test_kit_status,
    status_name,
    status_ts,
    rejected
  } = test;
  let image = PCR;
  const isBloodTest = type?.startsWith("blood-");

  if (type?.startsWith("dna-")) {
    if (type === "dna-weight") {
      image = iconHelper("Weight");
    } else {
      image = iconHelper(type_name);
    }
  }
  if (isBloodTest) image = iconHelper(type_name);

  const {
    label: assessmentLabel,
    link: assessmentLink,
    appendClass: assessmentAppendClass
  } = getAssessmentButtonDetails(
    test.assessment_id,
    test.can_edit_assessment,
    test.type,
    test.id,
    status_name
  );

  let link = `/${detail_link}/${id}/`;

  if (test_kit_status === "report-ready" && report_id) {
    link = `/my-reports/${(type || "")?.startsWith("blood-") ? "blood/" : ""
      }${report_id}`;
  }

  const displayBarcode =
    type?.startsWith("wellness-") ? true : false;

  let continueActivateBt = {};
  // If the test was activated but there is no test_ts, we'll show a button to continue the activation
  if (test_kit_status === "activating") {
    continueActivateBt = {
      button2_text: "Return to my activation",
      button2_link: `/${detail_link}/resume/${id}/`,
      button2_append_class: assessmentAppendClass
    };
  }


  let customerCareBtn,
    assessmentBtn = {};
  if (test_kit_status === "rejected" || test_kit_status === "error") {
    customerCareBtn = {
      button4_text: "Contact customer care",
      button4_link: "https://myhealthchecked.com/help",
      button4_target: "_blank"
    };
  } else {
    assessmentBtn = {
      button3_text: assessmentLabel,
      button3_link: assessmentLink,
      button3_append_class: assessmentAppendClass
    };
  }
  return (
    <LinkCard
      // If item has test_kit_type, redirect to /test-kits/:id, otherwise /tests/:id
      to={link}
      image={image}
      ts={format_date(status_ts)}
      title={type_name || as_title(type)}
      button_text={
        test_kit_status === "report-ready" && report_id ? `View results` : `View test`
      }
      button_append_class={
        test_kit_status === "rejected" || rejected ? `disabled ` : test_kit_status
      }
      {...continueActivateBt}
      {...assessmentBtn}
      {...customerCareBtn}
    >
      <div>
        <span className="text-muted d-none d-md-inline-block mr-2">
          {!displayBarcode ? "Barcode" : "Booking reference"}:&nbsp;
        </span>
        <code className="inline-code">
          {!displayBarcode ? primary_barcode : booking_reference}
        </code>
      </div>
      <div>
        <span className="text-muted d-none d-md-inline-block mr-2">
          Status: &nbsp;
        </span>
        <span className="text-dark font-weight-bold">
          {(!rejected && status_name) || (rejected && "Rejected")}
        </span>
      </div>
    </LinkCard>
  );
};
export default TestList;
