import React, { useEffect, useState } from "react";
import { AssessmentMessage } from "../Health Assessment/AssesmentMessage";
import TimelineItem from "./TimelineItem";
import WellnessTimeline from "./WellnessTimeline";
import DisplayItems from "./DisplayItems";
import { myResultUrl } from "../../utils/constants";
import useApis from "../../services/useAPI";
import { useParams, Link } from "react-router-dom";
import { steps } from "../Dashboard/Dashboard";
import { Alert, Button, Spinner } from "react-bootstrap";
import Markdown from "react-markdown";

// Displays test kit details
const TestKitDetails = () => {
  const params = useParams();
  const { test_kit_id } = params;
  // API hook
  const { callApi, resultGet, isLoading } = useApis();
  // Toggles between tests (for demo)
  const [testFlag, setTestFlag] = useState(1);

  // Fetches test kit details
  useEffect(() => {
    callApi(null, `${myResultUrl}${test_kit_id}/`, "GET");
  }, []);

  useEffect(() => {
    if (resultGet?.data?.test_kit_type?.includes("dna-")) {
      setTestFlag(0);
    }
  }, [resultGet?.data?.test_kit_type]);

  // Shows loading or error states
  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );
  }

  // Selects the current test based on testFlag
  const selectedTest = resultGet?.data;

  // Shows message if no test details are available
  if (!selectedTest) return <div>No test details available.</div>;

  // Prepares fields for display
  const fieldsArray = Object.keys(fields).map(key => ({
    name: key,
    ...fields[key]
  }));

  const assessment = {
    requireAssessment:
      selectedTest?.test_kit_type &&
      selectedTest?.test_kit_type.startsWith("blood-"),
    hasFilledAssessment: selectedTest?.assessment_id,
    canEditAssessment: selectedTest?.can_edit_assessment
  };

  const renderInstructions = () => {
    let nextStep = steps[currentStepIndex] || { title: () => null };

    // If next step is 'assessment, skip to another step
    if (nextStep.name === "assessment") {
      nextStep = steps[currentStepIndex + 2] || { title: () => null };
    }

    // If it is Menopause or PCOS test AND assessment wasn't filled
    // AND still can be filled - then show warning
    if (
      (selectedTest?.test_kit_type === "blood-menopause" ||
        selectedTest?.test_kit_type === "blood-polycystic") &&
      !assessment.hasFilledAssessment &&
      assessment.canEditAssessment
    )
      return (
        <div>
          <p className="text-justify">
            Our records show that we have not received your completed Health
            Assessment for <strong>{selectedTest?.test_kit_type_name}</strong>, and
            without this, you will not receive your results.
          </p>

          <p className="text-justify">
            The information you submit will provide health and lifestyle context
            for the doctor who reviews your results. We will also ask you
            questions about your menstrual cycle because blood results can
            depend on the phase of a woman’s cycle.
          </p>
        </div>
      );

    if (!nextStep.instructions) return null;

    return nextStep.instructions({
      test_id: selectedTest?.id,
      viewInstructionsUrl,
      filledAssessment: !!assessment.hasFilledAssessment,
      errorReason: selectedTest?.archive_reason,
      errorReasonMessage: selectedTest?.archive_reason_message,
      consultation_status: selectedTest?.consultation_status
    });
  };
  const anyStepFailed = steps.some(
    step =>
      step.failed?.(selectedTest?.test_kit_status, selectedTest?.archive_reason)
  );

  const currentStep = steps.find(step =>
    step.inProgress(selectedTest?.test_kit_status, selectedTest?.archive_reason)
  );
  const currentStepIndex = steps.findIndex(step => step === currentStep);
  let viewInstructionsUrl = "https://myhealthchecked.com/help/videos/blood";
  const isSexualHealthTest =
    selectedTest?.test_kit_type?.indexOf("sex-health") !== -1;
  if (isSexualHealthTest) {
    viewInstructionsUrl =
      "https://myhealthchecked.com/help/videos/sexual-health";
  }
  return (
    <div className="container-fluid">
      <div className="row justify-content-center pt-4 dashboard-page">
        <div className="mt-3 p-0 col-10">
          <div className="dashboard">
            <h1>{selectedTest.test_kit_type_name}</h1>
            {selectedTest.archive_reason_message ? (
              (selectedTest.error_note && selectedTest.archive_reason_message) ||
                selectedTest.error_note ? (
                <Alert className="rounded" variant="danger" dismissible={false}>
                  <Markdown>{selectedTest.error_note}</Markdown>
                </Alert>
              ) : selectedTest.archive_reason_message ? (
                <Alert className="rounded" variant="danger" dismissible={false}>
                  <Markdown>{selectedTest.archive_reason_message}</Markdown>
                </Alert>
              ) : null
            ) : (
              renderInstructions()
            )}
            <div className="mb-4">
              <div className="d-flex">
                {/* If has assessment, show button to view it */}
                {testFlag == 1 && assessment.hasFilledAssessment && !assessment.canEditAssessment && (
                  <Button className="outline-primary" href={`/assessment-details/${selectedTest?.id}`} color="primary" variant="outline-primary">
                    View health assessment
                  </Button>
                )}
              </div>
            </div>
            {testFlag === 1 ? (
              <>
                <TimelineItem
                  test={{ ...selectedTest }}
                  steps={steps}
                  assessment={assessment}
                  hasFilledAssessment={!!assessment.hasFilledAssessment}
                  canEditAssessment={!!assessment?.canEditAssessment}
                  currentStepIndex={currentStepIndex}
                  anyStepFailed={anyStepFailed}
                />
              </>
            ) : testFlag === 0 ? (
              <WellnessTimeline test={{ ...selectedTest }} />
            ) : null}

            {selectedTest?.test_kit_type?.includes('blood-') && !selectedTest?.bId && (
              <AssessmentMessage
                assessment={assessment}
                status={selectedTest?.test_kit_status}
                test_id={selectedTest?.id}
              />
            )}
            <h2 className="py-2">Test Summary</h2>
            <DisplayItems fields={fieldsArray} data={selectedTest} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestKitDetails;

// Field configurations for displaying test details (for demo)
const fields = {
  test_kit_type_name: { title: "Test Kit Type", fmt: "as-title" },
  test_kit_status_name: { title: "Status", fmt: "as-title" },
  sex: { title: "Gender at Birth", fmt: "as-title" },
  dob: { title: "Date of Birth", fmt: "date" },
  primary_barcode: { fmt: "inline-code" },
  secondary_barcode: { fmt: "inline-code" }
};
