import React, { useEffect, useState } from "react";
import useApis from "../../services/useAPI";
import { useAuth } from "../../components/AuthContext/AuthContext";
import DynamicForm from "../../stories/Forms/DynamicForm";
import { MfaFields } from "../../stories/Forms/fields";
import { useNavigate } from "react-router-dom";
let fields;
const EnableMFA = () => {
  const { callApi, resultGet, resultPost } = useApis();

  const auth = useAuth();
  const navigate = useNavigate();

  const url = `/api/dashboard/check-contact/${localStorage.getItem(
    "customer_id"
  )}`;

  const postUrl = `/api/dashboard/add-contact/`;
  const postUrl2 = `/api/dashboard/verify-otp`;

  const [ph_number, setPhNumber] = useState();

  const handleMFASubmit = async values => {
    const data = values.otp
      ? {
          otp: values.otp, // Use values.otp instead of data.otp
          user_id: localStorage.getItem("user_id")
        }
      : {
          mobile: values.primary_contact_no, // Use values.primary_contact_no
          user_id: localStorage.getItem("user_id")
        };

    const url = values.otp ? postUrl2 : postUrl;

    callApi(data, url, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json"
    });
  };

  useEffect(() => {
    callApi(null, url, "GET", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json"
    });
  }, []);

  useEffect(() => {
    resultGet && setPhNumber(resultGet);
    if (resultPost) {
      navigate("/confirm-otp");
    }
  }, [resultGet, resultPost]);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="py-4">
            <h1>EnableMFA</h1>
            <div className="my-4">
              <p>
                To help keep your account safe, we’re now asking all customers
                to turn on Multi-Factor Authentication (MFA).
              </p>{" "}
              <p>
                {" "}
                By adding this extra security step, your account will be even
                more protected. Simply provide your phone number, and we’ll send
                you a quick verification code to complete the setup.
              </p>
            </div>
            <DynamicForm
              fields={resultPost ? fields : MfaFields}
              onSubmit={handleMFASubmit}
              initialValues={ph_number}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EnableMFA;
