import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import useApis from "../../services/useAPI";

export default function ResumeActivateBlood() {
  const { test_kit_id } = useParams();
  const [redirect, setRedirect] = useState();

  // API hook
  const { callApi, resultGet, serverError } = useApis();

  useEffect(() => {
    callApi(
      null,
      `/api/dashboard/sample-collection/resume/${test_kit_id}`,
      "GET"
    );

    if (resultGet) {
      const { activation_stage, barcode, secondary_type } = resultGet;

      // Navigates based on the activation stage received from the API response
      switch (activation_stage) {
        case "barcode":
          if (secondary_type !== null) {
            setRedirect(`/secondary-barcode/${barcode}`);
          } else {
            setRedirect(`/consent/${barcode}/`);
          }
          break;
        case "confirm-barcode":
          setRedirect(`/consent/${barcode}/`);
          break;
        case "consent":
          setRedirect(`/confirm-details/${barcode}/`);
          break;
        case "personal-details":
          if (barcode.startsWith("C")) {
            setRedirect(`/wellness-collect-sample/${barcode}`);
          } else {
            setRedirect(`/essential-tips/${barcode}/`);
          }
          break;
        case "collect-sample":
          setRedirect(`/time-administered/${barcode}/`);
          break;
        case "test-taken":
          setRedirect(`/assessment/${test_kit_id}/1`);
          break;
      }
    }
  }, [resultGet]);

  // Shows error message if any
  if (serverError) {
    return <div className="alert alert-danger">Error: {serverError}</div>;
  }

  return redirect && <Navigate to={redirect} />;
}
