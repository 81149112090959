/* eslint-disable */
import React from "react";
import "../../../assets/scss/_marker-scale.scss";
import { getScaleDetails } from "../../../utils/reportUtils";
import MarkerProgressBar from "./MarkerScale/MarkerProgressBar";
import BoundaryLabels from "./MarkerScale/BoundaryLabels";
const lowBoundaryTypes = ["low-normal", "low-normal-high"];
const highBoundaryTypes = [
  "normal-high",
  "low-normal-high",
  "negative-positive"
];

/**
 * MarkerScale component for displaying a marker's scale and its boundaries.
 * @returns {JSX.Element} The rendered marker scale.
 */
const MarkerScale = props => {
  const { value, type } = props;
  const showLowBoundary = lowBoundaryTypes.includes(type);
  const showHighBoundary = highBoundaryTypes.includes(type);
  const isBoolMarker = type === "negative-positive";
  const normalBoundaryLabel = isBoolMarker ? "Not detected" : "Normal";
  let highBoundaryLabel = isBoolMarker ? "Detected" : "High";

  if (String(value).toLowerCase() === "unconfirmed reactive") {
    highBoundaryLabel = "Unconfirmed Reactive";
  }

  const { barSize, pointerPosition, leftBarSize } = getScaleDetails(props);

  const lowerBoundary = parseFloat(props.lower_boundary);
  const upperBoundary = parseFloat(props.upper_boundary);

  return (
    <div className="markerScale__container">
      <div
        className="markerScale__pointer"
        style={{ left: `${pointerPosition}%` }}
      ></div>

      <MarkerProgressBar
        showLowBoundary={showLowBoundary}
        leftBarSize={leftBarSize}
        barSize={barSize}
        showHighBoundary={showHighBoundary}
        value={value}
      />

      <BoundaryLabels
        showLowBoundary={true}
        leftBarSize={30}
        barSize={40}
        normalBoundaryLabel="Normal"
        highBoundaryLabel="High"
        decision_type={props.decision_type}
        type={props.type}
        lower_boundary={lowerBoundary}
        upper_boundary={upperBoundary}
        boundary={props.boundary}
      />
    </div>
  );
};

export default MarkerScale;
