import React from "react";
import { LinkCard } from "../../stories/cards/LinkCard";
import Certificate from "../../assets/icons/Platform/Results.svg";
import { useParams } from "react-router-dom";

const WellnessStart = () => {
  const params = useParams()
  const { test_kit_id } = params
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center pt-4 dashboard-page">
          <div className="mt-3 p-0 col-10">
            <div className="dashboard">
              <h1 className="mb-4">Assessments</h1>
              <div className="pt-sm-4 mt-4 mb-4">
                <div className="container activate-box-wrapper">
                  <div className="row">
                    <div className="col-md-12">
                      <LinkCard
                        to={`/wellness-assessment/${test_kit_id}`} image={Certificate}
                        title="Complete your wellness assessment questionnaire"
                        button_text="Start"
                        button_append_class="my-button-class"
                        button2_link="/"
                        button2_text="Skip"
                        button2_append_class="outline-button"
                      >
                        <div className="text-muted">
                          This could take up to 2 minutes to complete.
                        </div>
                      </LinkCard>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WellnessStart;
