// src/utils/mfaCookie.js
import Cookies from 'js-cookie';

// Set MFA Timestamp when user logs in successfully using MFA
export const setMfaTimestamp = () => {
  const currentTime = new Date().toISOString();
  // Store the timestamp in a cookie with 1 month expiration
  Cookies.set('mfaTimestamp', currentTime, { expires: 30 }); // 30 days
};

// Check if 1 month has passed since last MFA
export const isMfaRequired = () => {
  const mfaTimestamp = Cookies.get('mfaTimestamp');
  
  if (!mfaTimestamp) return true; // If no timestamp found, MFA is required
  
  const lastMfaDate = new Date(mfaTimestamp);
  const currentDate = new Date();
  const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;

  // Check if the current date is more than one month from the last MFA date
  return currentDate - lastMfaDate > oneMonthInMilliseconds;
};
