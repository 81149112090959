import React, { useEffect, useRef } from "react";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { BoxArrowInRight, XSquare } from "react-bootstrap-icons";
import useApis from "../../services/useAPI";
import { useLocation, Navigate } from "react-router-dom";

const ConfirmAccount = () => {
  const auth = useAuth();
  const location = useLocation();
  const token = location.pathname.split("/")[2];
  const url = `/api/dashboard/activate-account/${token}/`;
  const { callApi, resultGet, serverError } = useApis();

  const apiCalledRef = useRef(false);
  const [redirect, setRedirect] = React.useState(null);

  // Triggers account activation process
  useEffect(() => {
    if (token && !apiCalledRef.current) {
      apiCalledRef.current = true;
  
      (async () => {
        callApi(null, url, "GET");

      })();
    }
  }, [token, url, auth]);
useEffect(()=>{
  
  if (resultGet) {
    auth.setTokens(
      resultGet.data.jwt_token,
      resultGet.data.email,
      resultGet.data.role,
    );
    auth.setIsAuthenticated(true);
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("user_id", resultGet.data.id);
    localStorage.setItem("username", resultGet.data.fullname);
    auth.showToast(
      "Confirmed",
      resultGet?.message,
      "../assets/mhc.png",
      <BoxArrowInRight />
    );
    setRedirect("/");
  } else if (serverError) {
    console.error("API error occurred:", serverError);
    setRedirect("/create-account");
    auth.showToast(
      "Error",
      "Invalid Link",
      "../assets/mhc.png",
      <XSquare />
    );
  }
},[resultGet, serverError])

  // Handle redirection if needed
  if (redirect) {
    return <Navigate to={redirect} replace />;
  }

  return (
    <div className="initial-form-wrapper">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="py-4">
            <h1> Create account </h1>
            <div className="lead">
              <p>
                Please check your email to confirm your new account and login.
              </p>
              <p>You can safely close this window.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAccount;
