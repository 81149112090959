import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { effect_data, getIntensity } from "../../utils/utils";
import { image_headers } from "../../views/WellnessReports/ReportImages";
import further_research_required from "../../assets/icons/further_research_required.svg";
import RecommendationSection from "../../views/WellnessReports/RecommendationSection";
import Scale from "../../views/WellnessReports/Scale";
import Row from "react-bootstrap/Row";
import ReactMarkdown from "react-markdown";

//Accordion to display report data
const ReportAccordion = ({
  items,
  userName,
  defaultActiveKey = ["0"],
}) => {

  // Controlled activeKey state
  const [activeKeys, setActiveKeys] = useState(defaultActiveKey);

  // Handler for accordion toggle
  const handleToggle = (eventKey) => {
    // If the item is already open, close it; otherwise, add it to activeKeys
    setActiveKeys(prevKeys =>
      prevKeys.includes(eventKey)
        ? prevKeys.filter(key => key !== eventKey)
        : [...prevKeys, eventKey]
    );
  };

  return (
    <Accordion activeKey={activeKeys}>
      {items?.map((item, index) => (
        <Accordion.Item eventKey={index.toString()} key={index}>
          {item.missing ? (
            <MissingHealthArea health_area={item} effect={item.effect} />
          ) : (
            <>
              <Accordion.Header onClick={() => handleToggle(index.toString())}>
                <div className="d-flex align-items-center justify-content-center w-100">
                  <div className="circle-img">
                    <img
                      src={image_headers[item.tag]}
                      alt={item.header}
                      height={100}
                      width={100}
                      style={{ marginRight: "8px" }}
                    />
                  </div>
                  <div className="scale-bar">
                    <span
                      className="sm-heading"
                      style={{ fontSize: "1.25rem", marginBottom: "0.5rem" }}
                    >
                      {item.name}
                    </span>
                    {getScaleBar(item.value, item.effect)}
                  </div>
                  <div className="empty-box"></div>
                </div>
              </Accordion.Header>
            </>
          )}
          <Accordion.Body>
            <div className="row">
              <div className="col-md-7">
                <h2 className="mb-3">{userName}'s Result</h2>
                <p className="results-summary">
                  <ReactMarkdown>{item.personalised_description}</ReactMarkdown>
                </p>
                <RecommendationSection
                  advisements={item.advisements}
                  advice={item.advice}
                />
              </div>
              <div className="col-md-5">
                {getScaleMeaning(item.value, item.effect, item.lead)}
                {getGeneTable(item.snps, item.further_research_required)}
              </div>
            </div>
            <Row className="mt-2 m-0">
              <p className="results-extra p-3 p-md-4 mb-0">
                <ReactMarkdown>{item.description}</ReactMarkdown>
              </p>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default ReportAccordion;

// Function to render scale bar
export const getScaleBar = (value, effect) => {
  const color = getIntensity(value, effect);
  const { leftLabel, centreLabel, rightLabel } = effect_data[effect];

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="p-0">
            <div className="dashboard">
              <Scale
                className={`text-${color}`}
                steps={2}
                color={color}
                leftLabel={leftLabel}
                centreLabel={centreLabel}
                rightLabel={rightLabel}
                percentage={value * 100}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Function to render the scale meaning based on value and effect
const getScaleMeaning = (value, effect, lead) => {
  const riskColour = getIntensity(value, effect);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="dashboard">
              <h2>Scale Meaning</h2>
              <div className={`p-2 mb-4 rounded-sm risk-bg-${riskColour}`}>
                <Risk risklevel={value} risktype={effect}></Risk>
                <p className="results-summary">{lead}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Function to render the gene table
const getGeneTable = (snps, further_research_required) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row dashboard-page">
          <div className="col">
            <div className="dashboard">
              <div>
                <h2>Gene Table</h2>
                <RawTableMini snps={snps} />
                <FurtherResearchRequired show={further_research_required} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Determines risk level based on risk level value
const Risk = ({ risklevel, risktype }) => {
  if (risklevel === 0) {
    return "Low " + risktype;
  } else if (risklevel === 0.5) {
    return "Medium " + risktype;
  }
  return "High " + risktype;
};

// Renders mini table for SNPs
const RawTableMini = ({ snps }) => {
  return (
    <>
      {snps && snps.length > 0 ? (
        <div>
          <table className="raw-report-table">
            <thead className="raw-report-header bg-dark">
              <tr>
                <th>Gene</th>
                <th>SNP</th>
                <th>Your Genotype</th>
              </tr>
            </thead>
            <tbody>
              {snps.map((item, key) => {
                const { gene, snp, base_pair } = item;
                return (
                  <tr key={key}>
                    <td>
                      <span> {gene} </span>
                    </td>
                    <td>
                      <span>{snp}</span>
                    </td>
                    <td>
                      <span>{base_pair}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
    </>
  );
};

// Renders further research requirement
const FurtherResearchRequired = ({ show }) => {
  return (
    <>
      {show ? (
        <>
          <div className="pt-4 results-summary">
            <table>
              <tbody>
                <td>
                  <img
                    src={further_research_required}
                    alt="Further Research Required"
                  />
                </td>
                <td className="pl-2">
                  This icon denotes further research is required in order to
                  fully meet MyHealthChecked internal standards on scientific
                  evidence. Download your PDF report to read more.
                </td>
              </tbody>
            </table>
          </div>
        </>
      ) : null}
    </>
  );
};

// Component to handle cases when health area data is missing
const MissingHealthArea = ({ health_area, effect }) => {
  const { color } = effect_data[effect];
  const { name } = health_area;
  return (
    <div className="rounded row p-3 bg-light report-detail-card missing">
      <div className="col-12">
        <h5 className={`text-${color}`}>{name}</h5>
        <p className="results-summary">
          Result missing{" "}
          <small>
            &mdash; The laboratory could not read this result from your sample,
            this could be because your cheek had residual food or drink or
            because there was not enough saliva on the cotton end. It is rare to
            have missing results.
          </small>
        </p>
      </div>
    </div>
  );
};